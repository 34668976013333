import SearchBuilder from "@/builder/SearchBuilder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AccountingNormalBalance } from "@/models/enums/AccountingNormalBalance.enum";
import {
  AccountingAccountCreateRequestDto,
  AccountingAccountResponseDto,
  AccountingAccountTypeResponseDto,
  AccountingAccountUpdateRequestDto,
} from "@/models/interface/accounting-account";
import {
  BaseResponse,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { DataCoa } from "@/models/interface/settings.interface";
import { accountingAccountService } from "@/services/AccountingAccount.service";
import { settingsServices } from "@/services/settings.service";
import { FormValue } from "@/store/accountingAccount.store";
import { initForm } from "@/store/resources/AccountingAccount.resource";

const useCoa = () => {
  /**
   * @deprecated
   * new hooks {@linkcode findList}
   */
  const findAll = (params: RequestQueryParamsModel) => {
    return settingsServices.listOfCoa(params, "") as Promise<
      Pagination<AccountingAccountResponseDto>
    >;
  };

  const findAllParentAccount = (
    params = new RequestQueryParams()
  ): Promise<Pagination<AccountingAccountResponseDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery: string = builder.push(["isParent", "true"]).build();
    const query: Array<string> = [defaultQuery];
    const search: string = params?.search || "";

    if (search) {
      query.unshift(search);
    }

    params.search = query.join(builder.AND);

    return accountingAccountService.getList(params);
  };

  const findAllChildAccount = (
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): Promise<Pagination<AccountingAccountResponseDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery: string = builder
      .push(["isParent", "false"])
      .and()
      .push(["active", "true"])
      .build();
    const query: Array<string> = [defaultQuery];
    const search: string = params?.search || "";

    if (search) {
      query.unshift(search);
    }

    params.search = query.join(builder.AND);

    return accountingAccountService.getList(params);
  };

  const findList = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AccountingAccountResponseDto>> => {
    return accountingAccountService.getList(params);
  };

  const findById = (id: string): Promise<AccountingAccountResponseDto> => {
    return accountingAccountService.detail(id);
  };

  const update = (
    id: string,
    payload: AccountingAccountUpdateRequestDto
  ): Promise<AccountingAccountResponseDto> => {
    return accountingAccountService.update(id, payload);
  };

  const create = (
    payload: AccountingAccountCreateRequestDto
  ): Promise<BaseResponse> => {
    return accountingAccountService.create(payload);
  };

  const deleteById = (id: string) => {
    return accountingAccountService.deleteCoa(id);
  };

  const findAllAccountType = (
    params?: RequestQueryParams
  ): Promise<Pagination<AccountingAccountTypeResponseDto>> => {
    return accountingAccountService.getListAccountType(params);
  };

  /**
   * @deprecated
   * new method {@linkcode toOptions}
   */
  const mapToOption = (data: Array<DataCoa>): Array<Option<DataCoa>> => {
    return data.map<Option<DataCoa>>(item => ({
      label: toLabel({ code: item.code, desc: item.description }),
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const toOptions = (
    data: Array<AccountingAccountResponseDto>
  ): Array<Option<AccountingAccountResponseDto>> => {
    return data.map(item => ({
      label: toLabel({ code: item.code, desc: item.description }),
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const toAccountTypeOptions = (
    data: Array<AccountingAccountTypeResponseDto>
  ): Array<Option<AccountingAccountTypeResponseDto>> => {
    return data.map(item => ({
      key: item.id,
      label: item.description,
      value: item.id,
      meta: item,
    }));
  };

  const filterBy = (
    field: Partial<{ code: string; description: string; currencyCode: string }>
  ): string => {
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (field.code) {
      q.push(builder.push(["code", field.code], { like: "both" }).build());
    }

    if (field.description) {
      q.push(
        builder
          .push(["description", field.description], { like: "both" })
          .build()
      );
    }

    if (field.currencyCode) {
      q.push(
        builder
          .push(["currency.currencyCode", field.currencyCode], { like: "both" })
          .build()
      );
    }

    return q.join(builder.OR);
  };

  const toLabel = (field: { code: string; desc: string }): string => {
    const { code = "", desc = "" } = field;
    return `${code} - ${desc}`;
  };

  const initCoaTypeDto = (): AccountingAccountTypeResponseDto => ({
    reportType: "",
    category: "",
    name: "",
    description: "",
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
  });

  const initCoaDto = (): AccountingAccountResponseDto => ({
    code: "",
    description: "",
    balance: 0,
    normalBalance: "" as AccountingNormalBalance,
    parentId: "",
    parentName: "",
    accountType: initCoaTypeDto(),
    currencyName: "",
    currencyId: "",
    active: false,
    parent: false,
    createdBy: "",
    createdDate: "",
    id: "",
    modifiedBy: "",
    modifiedDate: "",
  });

  const initCreateDto = (): AccountingAccountCreateRequestDto => ({
    accountTypeId: "",
    active: true,
    code: "",
    currencyId: "",
    description: "",
    normalBalance: "" as AccountingNormalBalance,
    parent: false,
    parentId: "",
  });

  const initUpdateDto = (): AccountingAccountUpdateRequestDto => ({
    accountTypeId: "",
    active: true,
    code: "",
    currencyId: "",
    description: "",
    normalBalance: "" as AccountingNormalBalance,
    parent: false,
    parentId: "",
    currencyName: "",
  });

  const mapFormToCreateDto = (
    form: FormValue
  ): AccountingAccountCreateRequestDto => {
    const req: AccountingAccountCreateRequestDto = initCreateDto();

    req.accountTypeId = form.accountTypeId;
    req.active = form.active;
    req.code = form.code;
    req.currencyId = form.currencyId;
    req.description = form.description;
    req.normalBalance = form.normalBalance;
    req.parent = form.parent;
    req.parentId = form.parentId;

    return req;
  };

  const mapFormToUpdateDto = (
    form: FormValue
  ): AccountingAccountUpdateRequestDto => {
    const req: AccountingAccountUpdateRequestDto = initUpdateDto();

    req.accountTypeId = form.accountTypeId;
    req.active = form.active;
    req.code = form.code;
    req.currencyId = form.currencyId;
    req.currencyName = form.currencyName;
    req.description = form.description;
    req.normalBalance = form.normalBalance;
    req.parent = form.parent;
    req.parentId = form.parentId;

    return req;
  };

  const mapDetailToForm = (detail: AccountingAccountResponseDto): FormValue => {
    const form: FormValue = initForm();

    form.accountTypeId = detail.accountType.id;
    form.accountTypeName = detail.accountType.name;
    form.active = detail.active;
    form.code = detail.code;
    form.currencyId = detail.currencyId;
    form.currencyName = detail.currencyName;
    form.description = detail.description;
    form.normalBalance = detail.normalBalance;
    form.parent = detail.parent;
    form.parentId = detail.parentId;
    form.parentName = detail.parentName;

    return form;
  };

  return {
    create,
    deleteById,
    filterBy,
    findAll,
    findAllAccountType,
    findAllParentAccount,
    findById,
    findList,
    initCoaDto,
    initCoaTypeDto,
    initCreateDto,
    initUpdateDto,
    mapFormToCreateDto,
    mapFormToUpdateDto,
    mapToOption,
    toAccountTypeOptions,
    toLabel,
    toOptions,
    update,
    mapDetailToForm,
    findAllChildAccount,
  };
};

export default useCoa;
