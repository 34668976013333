import { FILE_BUCKET } from "../constant/file-bucket.constant";

// AUTH
class ApiUrl {
  readonly MASTER_PATH_FORKLIFT = "/api/";

  // AUTH
  readonly LOGIN = this.getPath("oauth/token");

  // Users
  readonly ForgotPassword = this.getPath("v1/user/forgot/password");
  readonly ChangePassword = this.getPath("v1/user/password");
  readonly ChangePasswordAdmin = this.getPath("v1/user/admin/password");
  readonly Users = this.getPath("v1/user");
  readonly Menus = this.getPath("v1/menu");
  readonly Privilege = this.getPath("v1/privilege");
  readonly Role = this.getPath("v1/role");
  readonly UserDetailAuth = this.getPath("v1/user/detail/auth");

  // Settings
  readonly Coa = this.getPath("v1/accounting/account");
  readonly Tax = this.getPath("v1/accounting/tax");
  readonly Currency = this.getPath("v1/accounting/currency");
  readonly CurrencyConversion = this.getPath(
    "v1/accounting/currency/conversion"
  );
  readonly MasterUom = this.getPath("v1/product/uom");
  readonly AssetsBook = this.getPath("v1/asset/book");
  readonly InventoryLine = this.getPath("v1/logistic/inventory/line");
  readonly AccountType = this.getPath("v1/accounting/account/type");

  // COMPANY
  readonly Company = this.getPath("v1/company");
  readonly CompanyListAnother = this.getPath("v1/company/another");

  readonly Calendar = this.getPath("v1/calendar");
  readonly CalendarUpdate = this.getPath("v1/calendar/close/period");
  readonly Product = this.getPath("v1/product");
  readonly ProductPartNumber = this.getPath("v1/product/part-numbers");
  readonly ProductByStock = this.getPath("v1/product/in-stock");
  readonly SerialNumber = this.getPath("v1/product/purchase/serial-numbers");
  readonly SerialNumberForSales = this.getPath(
    "v1/product/purchase/serial-numbers/sales"
  );
  readonly TransferToAsset = this.getPath("v1/transfer/to/asset");
  readonly TransferToAssetListParamsV2 = this.getPath(
    "v2/transfer/to/asset/param"
  );
  readonly Merk = this.getPath("v1/product/merk");
  readonly PartNumber = this.getPath("v1/product/supplier");
  readonly ProductCategory = this.getPath("v1/product/category");
  readonly ProductCategoryByLocation = this.getPath(
    "v1/product/category/by/location"
  );
  readonly TaxCode = this.getPath("v1/tax/invoice/code");
  readonly TaxSerialNumber = this.getPath("v1/tax/invoice/sn");
  readonly TaxSerialNumberReport = this.getPath("v1/tax/invoice/sn/report");
  readonly TaxSerialNumberDetail = this.getPath("v1/tax/invoice/sn/detail");
  readonly TaxSerialNumberReminder = this.getPath("v1/tax/invoice/sn/reminder");
  readonly Preference = this.getPath("v1/preferences");

  // QR
  readonly QR = this.getPath("v1/asset/qr-code/detail");
  readonly QRAssetAddition = this.getPath(
    "v1/asset/asset-addition/qr-code/detail"
  );
  readonly QrAsset = this.getPath("v1/asset/qr-code");

  // upload
  readonly uploadFileModul = this.getPath("v1/file");

  // Assets
  readonly Category = this.getPath("v1/asset/category");
  readonly AssetCategory = this.getPath("v1/asset/asset-category");
  readonly AssetCategoryActive = this.getPath("v1/asset/asset-category/active");
  readonly Segment = this.getPath("v1/asset/segment");
  readonly AssetAditions = this.getPath("v1/asset/asset-addition");
  readonly CancelAssetAdditions = this.getPath(
    "v1/asset/asset-addition/cancel"
  );
  readonly assetUpdateAddValue = this.getPath("v1/asset/add-value/");
  readonly AssetAditionsDraft = this.getPath("v1/asset/asset-addition/draft");
  readonly AssetAditionsTemplate = this.getPath(
    "v1/asset/asset-addition/uploadTemplate"
  );
  readonly AssetAdditionsUpload = this.getPath(
    "v1/asset/asset-addition/upload"
  );
  readonly AssetAdditionsPosting = this.getPath("v1/asset/asset-addition/post");
  readonly AssetHistory = this.getPath("v1/asset/asset-history");
  readonly AssetDepreciation = this.getPath("v1/asset/depreciation");
  readonly AssetMaster = this.getPath("v1/asset");
  readonly AssetActiveInactive = this.getPath(
    "v1/asset/adjustment/active-inactive"
  );
  readonly AssetMasterRent = this.getPath("v1/asset/rent");
  readonly AssetMasterLeasing = this.getPath("v1/asset/leasing");
  readonly AssetMasterForSO = this.getPath("v1/asset/available/so");
  readonly AssetMasterForReclass = this.getPath("v1/asset/asset-reclass");
  readonly AssetCorporateBook = this.getPath("v1/asset/book/corporate");
  readonly AssetRetirement = this.getPath("v1/asset/retirement");
  readonly AssetAdjustmentFrom = this.getPath("v1/asset/adjustment/form");
  readonly AssetAdjustmentFinancial = this.getPath(
    "v1/asset/adjustment/financial"
  );
  readonly AssetAdjustmentAssignment = this.getPath(
    "v1/asset/adjustment/assignment"
  );
  readonly AssetAdditionTemplateDownload = this.getPath(
    "v1/asset/asset-addition/template"
  );
  readonly AssetInquiry = this.getPath("v1/asset/inquiry");
  readonly AssetInquiryDetail = this.getPath("v1/asset/inquiry/detail");
  readonly AssetTag = this.getPath("v1/asset/tag");
  readonly getReportAsset = this.getPath("v1/asset/report");
  readonly getReportAssetAdditions = this.getPath(
    "v1/asset/asset-addition/report"
  );
  readonly getReportAssetRetirement = this.getPath(
    "v1/asset/retirement/report"
  );
  readonly getReportAssetRetirementDownload = this.getPath(
    "v1/asset/retirement/report/download"
  );
  readonly getReportAssetRetirementPrint = this.getPath(
    "v1/asset/retirement/report/print"
  );
  readonly getReportAssetAdjustment = this.getPath("v1/asset/adjustment");
  readonly getReportAssetAdjustmentDownload = this.getPath(
    "v1/asset/adjustment/report/download"
  );
  readonly getReportAssetAdjustmentPrint = this.getPath(
    "v1/asset/adjustment/report/print"
  );
  readonly getAssetAdjustment = this.getPath("v1/asset/adjustment/detail");
  readonly reclass = this.getPath("v1/asset/reclass");
  readonly AssetAdditionReport = this.getPath("v1/asset/asset-addition/report");
  readonly HistoryAssetDepre = this.getPath("v1/asset-depreciation/history");
  readonly AssetHistoryReport = this.getPath("v1/asset/asset-history/report");
  readonly AssetHistoryReportDownload = this.getPath(
    "v1/asset/asset-history/report/download"
  );

  //General Journal
  readonly generalJournal = this.getPath("v1/accounting/generaljournal");

  //Sales Order
  readonly InternalContract = this.getPath("v1/internal/contract");
  readonly ContractReminder = this.getPath("v2/internal/contract/reminder");
  readonly DownloadContractReminder = this.getPath(
    "v2/internal/contract/reminder/download"
  );
  readonly AvailableInternalContract = this.getPath(
    "v1/internal/contract/available"
  );
  readonly InternalContractChangeLocation = this.getPath(
    "v1/ic/change/location"
  );
  readonly Sales = this.getPath("v1/sales");
  readonly CloseSalesOrder = this.getPath("v1/sales/close");
  readonly SalesHeaders = this.getPath("v1/sales/headers");
  readonly SalesHeader = this.getPath("v1/sales/headers");
  readonly AvailableSalesOrder = this.getPath("v1/sales/available/ar");
  readonly SalesTracking = this.getPath("v1/sales/tracking");
  readonly SalesSubmit = this.getPath("v1/sales/submit");
  readonly ClosePeriodSales = this.getPath("v1/sales/close-period");
  readonly GetDraftReturnItem = this.getPath("v1/sales/return-item/draft");
  readonly ListAccessories = this.getPath(
    "v1/sales/delivery-order/accessories"
  );
  readonly SalesReportDownload = this.getPath("v1/sales/download/report");
  readonly BastReady = this.getPath("v1/acceptance/of/order");
  readonly BastExchange = this.getPath("v1/ic/change/unit");
  readonly ReportDailyOperational = this.getPath("v1/report/daily/operational");
  readonly ReportUnitRented = this.getPath("v1/report/sales/unit/rented");
  readonly ReportUnitPosition = this.getPath("v1/report/sales/unit/position");
  readonly ReportHistoryUnit = this.getPath(
    "v1/report/sales/unit/history/mutation"
  );
  readonly ReportUnitMargin = this.getPath("v1/sales/margin-sales");

  //Picking list
  readonly PickingList = this.getPath("v1/picking/list");
  readonly PickingListChangeUnit = this.getPath("v1/picking/list/change/unit");
  readonly PickingListPrint = this.getPath("v1/picking/list/report/print");

  //Delivery Order
  readonly DeliveryOrder = this.getPath("v1/sales/delivery-order");
  readonly AvailableDeliveryOrder = this.getPath(
    "v1/sales/delivery-order/available/ar"
  );
  readonly AvailableDeliveryOrderForReturnCust = this.getPath(
    "v1/sales/delivery-order/available/return-to-customer"
  );
  readonly DeliveryOrderPrint = this.getPath("v1/sales/delivery-order/print");

  // Contact Data
  readonly Region = this.getPath("v1/region");
  readonly ContactData = this.getPath("v1/contact-data");
  readonly ContactDataSyncAll = this.getPath("internal/contact-data/sync-all");

  // Master
  readonly MasterType = this.getPath("v1/master/type");
  readonly SalesChart = this.getPath("v1/sales/monthly/sales");

  // Bank
  readonly Bank = this.getPath("v1/bank");
  readonly BankCompany = this.getPath("v1/company/bank");
  readonly BankCompanySyncAll = this.getPath("v1/company/bank/sync-all");
  readonly BankCompanyLastSyncDate = this.getPath(
    "v1/company/bank/last-sync-date"
  );

  // Warehouse
  readonly Warehouse = this.getPath("v1/logistic/warehouse");
  readonly WarehouseBranch = this.getPath("v1/logistic/warehouse/branch");
  readonly WarehouseLocation = this.getPath("v1/logistic/warehouse/location");
  readonly WarehouseTransfer = this.getPath("v1/logistic/warehouse/transfer");
  readonly WarehouseTransferCancel = this.getPath(
    "v1/logistic/warehouse/transfer/cancel"
  );
  readonly WarehouseTransferReceive = this.getPath(
    "v1/logistic/warehouse/transfer/receive"
  );
  readonly WarehouseTransferSubmit = this.getPath(
    "v1/logistic/warehouse/transfer/submit"
  );

  readonly StockAdjustment = this.getPath("v1/logistic/stock-adjustment");
  readonly ReportValuation = this.getPath("v1/logistic/stock-report/valuation");
  readonly ReportKartuStock = this.getPath(
    "v1/logistic/stock-report/stock-card"
  );
  readonly StockCardReportV2 = this.getPath(
    "v2/logistic/stock-report/stock-card"
  );

  //logistic
  readonly ReceiveItemDocument = this.getPath(
    "v1/logistic/receive-item/doc-no"
  );
  readonly ReceiveItemAvailableInvoiceAp = this.getPath(
    "v1/logistic/receive-item/available/invoice-ap"
  );

  readonly ReceiveItem = this.getPath("v1/logistic/receive-item");
  readonly ReceiveItemChecklist = this.getPath(
    "v1/logistic/receive-item/checklist"
  );
  readonly ReceiveItemChecklistPrint = this.getPath(
    "v1/logistic/receive-item/reportForCheck/print"
  );
  readonly ReceiveItemDownload = this.getPath(
    "v1/logistic/receive-item/report/download"
  );
  readonly ReceiveItemPrint = this.getPath(
    "v1/logistic/receive-item/report/print"
  );
  readonly ReceiveItemDraft = this.getPath("v1/logistic/receive-item/draft");
  readonly ReceiveItemSubmit = this.getPath("v1/logistic/receive-item/submit");
  readonly ReceiveItemUpdateCheck = this.getPath(
    // "v1/logistic/receive-item/submitForCheck"
    "v1/logistic/receive-item/submit"
  );
  readonly ProductReport = this.getPath("v1/product/report");

  readonly Workorder = this.getPath("v1/logistic/work-order");
  readonly WorkorderHeaders = this.getPath("v1/logistic/work-order/headers");
  readonly WorkorderList = this.getPath("v1/logistic/work-order/list");
  readonly WorkorderListDownload = this.getPath(
    "v1/logistic/work-order/list/report/download"
  );
  readonly WorkorderListPrint = this.getPath(
    "v1/logistic/work-order/list/report/print"
  );

  readonly WorkorderDraft = this.getPath("v1/logistic/work-order/draft");
  readonly DownloadCsfList = this.getPath(
    "v1/logistic/work-order/csf/report/download"
  );
  readonly DownloadCsfWorkorder = this.getPath(
    "v1/logistic/work-order/print/csf"
  );
  readonly PrintCSFFromWorkOrder = this.getPath(
    "v1/logistic/work-order/print/csf-wo"
  );
  readonly DownloadListWorkorder = this.getPath(
    "v1/logistic/work-order/report/download"
  );
  readonly PrintListWorkorder = this.getPath(
    "v1/logistic/work-order/report/print"
  );
  readonly ClosePeriodInventory = this.getPath(
    "v1/logistic/inventory/close-period"
  );
  readonly ReturnNumber = this.getPath("v1/sales/return-item");
  readonly DownloadReturnFromCustomer = this.getPath(
    "v1/sales/return-item/report/download"
  );
  readonly PrintReturnFromCustomer = this.getPath(
    "v1/sales/return-item/report/print"
  );
  readonly DetailUomConversions = this.getPath("v1/product/uom/conversion");
  readonly FifoReport = this.getPath("v1/product/report-fifo/valuation");
  readonly FifoReportListV2 = this.getPath("v2/product/report-fifo/valuation");
  readonly FifoReportDownloadV2 = this.getPath(
    "v2/product/report-fifo/valuation/download"
  );

  //Invoice Prepayment
  /**
   * @deprecated
   * use {@linkcode InvoiceArPrepayment}
   */
  readonly InvoicePrepayment = this.getPath("v1/ar/invoice-prepayment");

  readonly InvoiceArPrepayment = this.getPath("v1/ar/invoice-prepayment");

  readonly InvoicePrepaymentLeasing = this.getPath(
    "v1/ap/invoice-prepayment/leasing"
  );
  readonly CancelInvoicePrepayment = this.getPath(
    "v1/ar/invoice-prepayment/cancel"
  );

  readonly InvoicePrepaymentCode = this.getPath(
    "v1/ar/invoice-prepayment/tax-number"
  );

  // Invoice
  readonly InvoiceRecurring = this.getPath("v1/ar/invoice/recurring");
  readonly InvoiceAR = this.getPath("v1/ar/invoice");
  readonly InvoiceARSummary = this.getPath("v1/ar/invoice/summary");
  readonly InvoiceArListBap = this.getPath("v1/ar/invoice/list-bap");
  readonly InvoiceArBapValidation = this.getPath("v1/ar/invoice/bap/validate");
  readonly InvoiceArDetailIcBillings = this.getPath("v1/ar/invoice/bap");
  readonly ReportMarginUnit = this.getPath("v1/ar/invoice/report-margin-unit");
  readonly ReportMarginUnitV2 = this.getPath(
    "v2/ar/invoice/report-margin-unit"
  );
  readonly InvoiceARSubmit = this.getPath("v1/ar/invoice/submit");
  readonly InvoiceARCancel = this.getPath("v1/ar/invoice/cancel");
  readonly InvoiceARReject = this.getPath("v1/ar/invoice/reject");
  readonly InvoiceARApprove = this.getPath("v1/ar/invoice/approve");
  readonly InvoiceARCode = this.getPath("v1/ar/invoice/tax-number");
  readonly JoinInvoice = this.getPath("v1/ar/invoice-join");
  readonly JoinInvoiceAndPrepayment = this.getPath(
    "v1/ar/invoice-join/ar-prepayment"
  );
  readonly ReportTaxInvoice = this.getPath("v1/ar/invoice-join/printCSV");

  // AR Receipt
  readonly ArReceipt = this.getPath("v1/ar/receipt");
  readonly ArReceiptList = this.getPath("v1/ar/receipt/list");
  readonly GenerateArReceipt = this.getPath("v1/ar/invoice/generate-receipt");
  readonly PrintArReceipt = this.getPath("v1/ar/invoice/print-receipt");

  readonly UploadInvoice = this.getPath("v1/file/invoice");

  //Upload
  readonly UploadProduct = this.getPath("v1/file/product");
  readonly UploadAddition = this.getPath("v1/asset/asset-addition/upload");
  readonly UploadGeneralJournal = this.getPath("v1/file/generaljournal");
  readonly UploadCompanyLogo = this.getPath("v1/file/company");
  readonly UploadLogistic = this.getPath("v1/file/logistic");
  readonly UploaderInternalContract = this.getPath(
    "v1/internal/contract/upload"
  );

  // Aging
  readonly Aging = this.getPath("v1/report/aging");

  // AGING REPORT V2
  readonly AgingDashboardV2 = this.getPath("v2/report/aging/dashboard");
  readonly AgingDetailDashboardV2 = this.getPath(
    "v2/report/aging/dashboard/detail"
  );

  readonly InvoiceOverdue = this.getPath("v1/ar/invoice/recurring/overdue");

  // Unit Controlling
  readonly UnitControlling = this.getPath("v1/report/invoice/controlling");
  readonly UnitControllingV2 = this.getPath("v2/report/invoice/controlling");
  readonly UnitControllingDownloadV2 = this.getPath(
    "v2/report/invoice/controlling/download"
  );
  readonly UnitControllingV3 = this.getPath("v3/report/invoice/controlling");
  readonly UnitControllingDownloadV3 = this.getPath(
    "v3/report/invoice/controlling/download"
  );

  // Purchase
  readonly PurchaseOrder = this.getPath("v1/purchase/po");
  readonly PurchaseOrderExistPrepayment = this.getPath(
    "v1/purchase/po/exist-prepayment"
  );
  readonly PurchaseOrderAvailableAP = this.getPath(
    "v1/purchase/po/available/invoice-ap"
  );

  // Unit Purchase Requisition
  readonly PurchaseRequisition = this.getPath("v1/purchase/requisition");
  readonly ApproverPurchaseRequisition = this.getPath(
    "v1/purchase/requisition/approver"
  );
  readonly StatusPurchaseRequisition = this.getPath(
    "v1/purchase/requisition/status"
  );
  readonly RequestTypePurchaseRequisition = this.getPath(
    "v1/purchase/requisition/requestType"
  );

  // Return To Supplier
  readonly ReturnToSupplier = this.getPath("v1/purchase/return-item");
  readonly ReturnToSupplierDownload = this.getPath(
    "v1/purchase/return-item/download"
  );
  readonly GrReturnToSupplier = this.getPath(
    "v1/logistic/receive-item/available/return/to/supplier"
  );
  readonly GrDetailReturnToSupplier = this.getPath(
    "v1/logistic/receive-item/detail/return-to-supplier"
  );

  // Cash Management
  readonly BankTransfer = this.getPath("v1/cash/bank/transfer");
  readonly CashOut = this.getPath("v1/cash-in-out");
  readonly CashInOutList = this.getPath("v1/cash-in-out/list/cash");
  readonly CashInOutListDownload = this.getPath(
    "v1/cash-in-out/report/download/cash"
  );
  readonly ReportCashFlow = this.getPath("v1/report/arus/kas");

  readonly SubmitCashOut = this.getPath("v1/cash-in-out/submit");
  readonly CancelCashOut = this.getPath("v1/cash-in-out/cancel");
  readonly CashInOutPrint = this.getPath("v1/cash-in-out/print");

  // PPh
  readonly InputPPh = this.getPath("v1/input-pph");
  readonly ARAvailableInputPPh = this.getPath("v1/ar/invoice/input-pph");

  // Invoice AP Prepayment
  readonly InvoiceApPrepayment = this.getPath("v1/ap/invoice-prepayment");

  // leasing
  readonly Leasing = this.getPath("v1/leasing");
  readonly LeasingInvoiceApGenerate = this.getPath(
    "v1/ap/invoice/leasing/invoice"
  );

  // report kartu hutang leasing
  readonly DebtCardLeasingReport = this.getPath(
    "v1/leasing/debt/movement/report"
  );
  readonly ReportKartuHutang = this.getPath("v1/report/kartu/hutang");

  // report aging ap
  readonly AgingReportAp = this.getPath("v1/report/aging/ap");

  // ap payment
  readonly InvoiceAp = this.getPath("v1/ap/invoice");
  readonly InvoiceApAvailable = this.getPath(
    "v1/purchase/return-item/invoice-ap/available"
  );

  // ap payment
  readonly ApPayment = this.getPath("v1/ap-payment");
  readonly PPhSupplier = this.getPath("v1/ap-payment/pph-supplier");
  readonly PPhCustomer = this.getPath("v1/input-pph/pph-customer");

  readonly TrialBalance = this.getPath(
    "v1/accounting/generaljournal/trial-balance"
  );

  readonly DownloadTrialBalance = this.TrialBalance + "/download";

  readonly BankMovement = this.getPath("v1/cash-bank/movement");
  readonly Settlement = this.getPath("v1/cash-bank/settlement");
  readonly TransactionType = this.getPath(
    "v1/cash-bank/settlement/transaction-type"
  );

  readonly CashBank = this.getPath("v1/cash-bank");

  readonly JournalTransaction = this.getPath(
    "v1/accounting/report/journal-transaction"
  );
  readonly AccountingReport = this.getPath("v1/accounting/report");

  readonly TrackingIc = this.getPath("v1/internal/contract/tracking");

  readonly ReportPayableMutation = this.getPath(
    "v1/ap/invoice/report-payable-mutation"
  );

  readonly PurchaseOrderReceive = this.getPath(
    "v1/purchase/po/available/receive"
  );

  // TRUCKING
  readonly TruckingMasterDriver = this.getPath("v1/trucking/driver");
  readonly TruckingMasterDriverDownload = this.getPath(
    "v1/trucking/driver/download"
  );

  readonly TruckingMasterRate = this.getPath("v1/trucking/rate");
  readonly TruckingMasterRateDownload = this.getPath(
    "v1/trucking/rate/download"
  );

  // TRUCKING CASH OUT
  readonly TruckingCashInOutList = this.getPath("v1/cash-in-out/list/trucking");
  readonly TruckingCashInOutTypes = this.getPath(
    "v1/cash-in-out/type/trucking"
  );
  readonly TruckingCashInOutListDownload = this.getPath(
    "v1/cash-in-out/report/download/trucking"
  );

  // TRUCKING PAYMENT
  readonly TruckingMasterPayment = this.getPath("v1/trucking/payment");
  readonly TruckingMasterPaymentList = this.getPath("v1/trucking/payment/list");
  readonly TruckingMasterPaymentDownload = this.getPath(
    "v1/trucking/payment/download"
  );

  readonly TemplateInvoiceAR = this.getPath(
    "v1/file/template/Template_Upload_Invoice_AR.xlsx"
  );
  readonly TemplateInternalContract = this.getPath(
    "v1/file/template/Template_Upload_Internal_Contract.xlsx"
  );
  readonly TemplateCashInOut = this.getPath(
    "v1/file/template/Template_Upload_Cash_In_Out.xlsx"
  );
  readonly TemplateTruckingSettlement = this.getPath(
    "v1/file/template/Template_Upload_Trucking_Settlement.xlsx"
  );

  readonly UploaderInvoiceAR = this.getPath("v1/ar/invoice/upload");
  readonly UploaderCashInOut = this.getPath("v1/cash-in-out/upload");
  readonly UploaderTruckingSettlement = this.getPath(
    "v1/trucking/settlement/upload"
  );

  readonly TruckingSalesOrder = this.getPath("v1/sales/trucking");
  readonly TruckingSalesOrderHeader = this.getPath("v1/sales/trucking/headers");
  // Trucking Settlement
  readonly TruckingSettlementList = this.getPath("v1/trucking/settlement");
  readonly TruckingSettlementDetailReportList = this.getPath(
    "v1/trucking/settlement/report-detail"
  );
  readonly TruckingSettlementDetailReportDownload = this.getPath(
    "v1/trucking/settlement/report-detail/download"
  );
  readonly TruckingSettlementDownload = this.getPath(
    "v1/trucking/settlement/download"
  );
  readonly TruckingSettlementDetail = this.getPath(
    "v1/trucking/settlement/detail"
  );
  readonly TruckingSettlementApprove = this.getPath(
    "v1/trucking/settlement/approval"
  );
  readonly TruckingSettlementReject = this.getPath(
    "v1/trucking/settlement/reject"
  );
  readonly TruckingSettlementPostJournal = this.getPath(
    "v1/trucking/settlement/post-journal"
  );

  // TRUCKING ACCOUNT RECEIVABLE
  readonly TruckingAccountReceivableInvoiceList = this.getPath(
    "v1/ar/invoice/list/trucking"
  );

  // UJO TRACKING
  readonly UjoTrackingReportList = this.getPath("v1/ujo-tracking");
  readonly UjoTrackingReportDownload = this.getPath("v1/ujo-tracking/download");

  // UNIT RECEIPT MONITORING
  readonly UnitReceiptMonitoringList = this.getPath(
    "v1/unit-receipt-monitoring"
  );
  readonly UnitReceiptMonitoringDownload = this.getPath(
    "v1/unit-receipt-monitoring/download"
  );
  readonly UnitReceiptMonitoringAttachmentBucket = this.getPath(
    `v1/file/${FILE_BUCKET.UnitReceiptMonitoring}`
  );
  readonly UnitReceiptMonitoringReceipt = this.getPath(
    "v1/internal/contract/receive-date"
  );

  // CSF
  readonly CsfList = this.getPath("v1/logistic/work-order/csf");

  // RESULT WORK ORDER
  readonly WorkOrderResultFilter = this.getPath(
    "v1/logistic/work-order/result/filter"
  );
  readonly WorkOrderResult = this.getPath("v1/logistic/work-order/result");
  readonly WorkOrderResultCancel = this.getPath(
    "v1/logistic/work-order/result/cancel"
  );

  // OPEN CLOSE PERIOD YEARLY
  readonly OpenClosePeriodYearAvailableList = this.getPath(
    "v1/calendar/close-period/year/available"
  );
  readonly OpenClosePeriodYearModules = this.getPath(
    "v1/calendar/close-period/year/modules"
  );
  readonly OpenClosePeriodYear = this.getPath("v1/calendar/close-period/year");

  // ASSET MUTATION
  readonly AssetMutationList = this.getPath("v1/asset-mutation");
  readonly AssetMutationListDetail = this.getPath("v1/asset-mutation/detail");
  readonly AssetMutationListDownload = this.getPath(
    "v1/asset-mutation/download"
  );
  // SPAREPART REQUEST
  readonly SparepartRequest = this.getPath("v1/desktop/spare-part");
  readonly SparepartRequestReject = this.getPath(
    "v1/desktop/spare-part/reject"
  );
  readonly SparepartRequestDetail = this.getPath(
    "v1/desktop/spare-part/detail"
  );
  readonly SparepartRequestDownload = this.getPath(
    "v1/desktop/spare-part/download"
  );
  // ASSET TRANSFER
  readonly AssetTransfer = this.getPath("v1/asset-transfer");
  readonly CancelAssetTransfer = this.getPath("v1/asset-transfer/cancel");
  readonly ReceiveAssetTransfer = this.getPath("v1/asset-transfer/receive");
  readonly PrintAssetTransfer = this.getPath("v1/asset-transfer/print");
  readonly AssetTransferAttachmentBucket = this.getPath(
    `v1/file/${FILE_BUCKET.AssetTransfer}`
  );
  readonly AssetTransferDownload = this.getPath("v1/asset-transfer/download");

  // ASSET SEGMENT
  readonly AssetSegmentParent = this.getPath("v1/asset/segment/parent");

  // LEASING PAYMENT PLAN
  readonly LeasingPaymentPlanReportList = this.getPath(
    "v1/leasing/report/payment-plan"
  );
  readonly LeasingPaymentPlanReportDownload = this.getPath(
    "v1/leasing/report/payment-plan/download"
  );

  readonly AmortizationTransactionTypes = this.getPath(
    "v1/amortization/transaction-type"
  );
  readonly AmortizationList = this.getPath("v1/amortization/list");
  readonly Amortization = this.getPath("v1/amortization");
  readonly AmortizationCancel = this.getPath("v1/amortization/cancel");
  readonly AmortizationDetail = this.getPath("v1/amortization/detail");
  readonly AmortizationSubmit = this.getPath("v1/amortization/submit");
  readonly CloseAmortization = this.getPath("v1/amortization/close");
  readonly CloseAmortizationDetail = this.getPath(
    "v1/amortization/close/detail"
  );
  readonly DownloadAmortizations = this.getPath(
    "v1/amortization/list/download"
  );

  // AMORTIZATION TRANSACTION REPORT
  readonly AmortizationTransactionReport = this.getPath(
    "v1/amortization/transaction-report"
  );
  readonly AmortizationTransactionReportDownload = this.getPath(
    "v1/amortization/transaction-report/download"
  );

  // LEASING DEBT RECAP REPORT
  readonly LeasingDebtRecapReportDetail = this.getPath(
    "v1/leasing/report/debt-recap/detail"
  );
  readonly DownloadLeasingDebtRecapReportDetail = this.getPath(
    "v1/leasing/report/debt-recap/detail/download"
  );
  readonly LeasingDebtRecapReportSummary = this.getPath(
    "v1/leasing/report/debt-recap/summary"
  );
  readonly DownloadLeasingDebtRecapReportSummary = this.getPath(
    "v1/leasing/report/debt-recap/summary/download"
  );

  // AMORTIZATION REPORT
  readonly AmortizationReport = this.getPath("v1/amortization/report");
  readonly AmortizationReportDownload = this.getPath(
    "v1/amortization/report/download"
  );

  private getPath(path: string): string {
    return this.MASTER_PATH_FORKLIFT + path;
  }
}

// Export as Object
export const Api = new ApiUrl();

// Export as Class
export default ApiUrl;
