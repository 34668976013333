import useArPrepayment from "@/hooks/useArPrepayment";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const aRPrepaymentCreateLoader: NavigationGuard = (_to, _from, next) => {
  store.dispatch("aRPrepaymentStore/resetStore");
  next();
};

export const aRPrepaymentDetailLoader: NavigationGuard = async (
  to,
  _from,
  next
) => {
  try {
    const { findById } = useArPrepayment();
    const res = await findById(to.params.id);
    store.dispatch("aRPrepaymentStore/resetStore");
    store.dispatch("aRPrepaymentStore/mapDetailToFormDetail", res);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
