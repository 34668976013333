import SearchBuilder from "@/builder/SearchBuilder";
import { removeDuplicateObj } from "@/helpers/common";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ContactDataCreateDto,
  DetailContactDataDto,
} from "@/models/interface/contact-data";
import {
  ContactData,
  ResponseContactData,
  ResponseListContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import {
  AddressDataDto,
  AddressDataLineDto,
  ListContactDataDto,
} from "@interface/contact-data";
import { useFindMasterType } from "./master-type";

const useContactListFindById = async (
  contactId: string
): Promise<ContactData> => {
  const { data } = await contactServices.listContactData({
    search: `secureId~${contactId}`,
  });
  const [item] = data;
  return item;
};

const useFindAllActiveCustomer = async (
  params: RequestQueryParamsModel
): Promise<ResponseListContactData> => {
  const QUERY_CUSTOMER_ACTIVE = "customer~true_AND_active~true";
  const $params = new RequestQueryParams(
    QUERY_CUSTOMER_ACTIVE,
    params.page,
    params.sorts,
    params.limit
  );
  if (params.search) {
    $params.search = params.search + "_AND_" + QUERY_CUSTOMER_ACTIVE;
  }
  const response = await contactServices.listContactData($params);
  return response;
};

/**
 * @deprecated
 * use {@linkcode toOptions}
 */
const useMapContactToOption = (
  source: ResponseListContactData
): Option<ContactData>[] => {
  const setLabel = (data: ContactData) => {
    let label = data.firstName || "-";
    if (data.fullName && data.fullName.includes("null")) {
      label = data.fullName.replace("null", "-");
    }
    return label;
  };

  return source.data.map<Option<ContactData>>(el => ({
    label: setLabel(el),
    value: el.id as string,
    key: el.id as string,
    meta: el,
  }));
};

const useContactData = () => {
  const create = (dto: ContactDataCreateDto): Promise<DetailContactDataDto> => {
    return contactServices.createContactData(dto);
  };

  const update = (
    id: string,
    dto: ContactDataCreateDto
  ): Promise<DetailContactDataDto> => {
    return contactServices.updateContactData(dto, id);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    return contactServices.getListContactData(params);
  };

  const findAllCustomerAndSupplier = (params: RequestQueryParamsModel) => {
    const builder = new SearchBuilder();
    const defaultQuery = builder
      .push(["customer", "true"])
      .and()
      .push(["supplier", "true"])
      .and()
      .push(["active", "true"])
      .build();
    if (params.search)
      params.search = params.search + builder.AND + defaultQuery;
    else params.search = defaultQuery;
    return contactServices.listContactData(params);
  };

  const findAllDealer = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery = builder
      .push(["supplierData.supplierType", "Dealer"], { like: "both" })
      .and()
      .push(["supplier", "true"])
      .and()
      .push(["active", "true"])
      .build();
    const query: Array<string> = [defaultQuery];
    if (params.search) {
      query.unshift(params.search);
    }
    params.search = query.join(builder.AND);
    return contactServices.getListContactData(params);
  };

  const findAllInsurance = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery = builder
      .push(["supplierData.supplierType", "Asuransi"], { like: "both" })
      .and()
      .push(["supplier", "true"])
      .and()
      .push(["active", "true"])
      .build();
    const query: Array<string> = [defaultQuery];
    if (params.search) {
      query.unshift(params.search);
    }
    params.search = query.join(builder.AND);
    return contactServices.getListContactData(params);
  };

  const findAllLessor = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery = builder
      .push(["supplierData.supplierType", "Leasing"], { like: "both" })
      .and()
      .push(["supplier", "true"])
      .and()
      .push(["active", "true"])
      .build();
    const query: Array<string> = [defaultQuery];
    if (params.search) {
      query.unshift(params.search);
    }
    params.search = query.join(builder.AND);
    return contactServices.getListContactData(params);
  };

  const transformContactName = (
    firstName: string,
    fullName: string
  ): string => {
    let name = fullName ?? firstName ?? "-";
    if (fullName && fullName.includes("null")) {
      name = fullName.replace("null", "-");
    }
    return name;
  };

  /**
   * @deprecated
   * use {@linkcode toOptionsCustomer}
   */
  const toOptionsNameCode = (data: ResponseListContactData) => {
    return data.data.map<Option<ContactData>>(item => {
      let name = item.firstName || "-";
      if (item.fullName && item.fullName.includes("null")) {
        name = item.fullName.replace("null", "-");
      }
      return {
        label: name + " (" + (item.customerNumber || "-") + ")",
        value: item.id || "",
        key: item.id || "",
        meta: item,
      };
    });
  };

  const toOptionsCustomer = (
    data: Array<ListContactDataDto>
  ): Array<Option<ListContactDataDto>> => {
    return data.map<Option<ListContactDataDto>>(item => {
      let name = item.firstName || "-";
      if (item.fullName && item.fullName.includes("null")) {
        name = item.fullName.replace("null", "-");
      }
      return {
        label: `${transformContactName(item.firstName, item.fullName)} (${
          item.customerNumber ?? "-"
        })`,
        value: item.id || "",
        key: item.id || "",
        meta: item,
      };
    });
  };

  const toOptionsCode = (data: ResponseListContactData) => {
    return data.data.map<Option>(item => ({
      label: item.customerNumber || "-",
      value: item.id || "",
      key: item.id || "",
    }));
  };

  const toSupplierOptions = (
    data: ListContactDataDto[],
    as: keyof ListContactDataDto = "id"
  ) => {
    return data.map<Option<ListContactDataDto>>(item => ({
      label: `${item.fullName} (${item.supplierNumber || "-"})`,
      value: item[`${as}`],
      key: item[`${as}`],
      meta: item,
    }));
  };

  const buildQueryFindEmployee = (): string => {
    const builder = new SearchBuilder();
    return builder
      .push(["employee", "true"])
      .and()
      .push(["active", "true"])
      .build();
  };

  const findAllMechanic = (
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const queryEmployee = buildQueryFindEmployee();
    const queryMechanic = builder
      .push(["employeeData.position", "Mekanik"])
      .build();
    const defaultQuery = [queryEmployee, queryMechanic];
    if (params.search) {
      defaultQuery.unshift(params.search);
    }
    params.search = defaultQuery.join(builder.AND);

    return contactServices.getListContactData(params);
  };

  const findSales = (params: RequestQueryParamsModel) => {
    const builder = new SearchBuilder();
    const q = builder
      .push(["employee", "true"])
      .and()
      .push(["active", "true"])
      .and()
      .push(["employeeData.position", "Sales"])
      .build();
    const defaultQuery = [q];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join(builder.AND);

    return contactServices.listContactData(params);
  };

  const findAllEmployee = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const q: string = builder
      .push(["employee", "true"])
      .and()
      .push(["active", "true"])
      .build();
    const defaultQuery = [q];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join(builder.AND);
    return contactServices.listContactData(params);
  };

  const findCustomers = (params: RequestQueryParamsModel) => {
    const builder = new SearchBuilder();
    const q = builder
      .push(["customer", "true"])
      .and()
      .push(["active", "true"])
      .build();
    const defaultQuery = [q];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join(builder.AND);

    return contactServices.getListContactData(params);
  };

  const findSuppliers = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListContactDataDto>> => {
    const builder = new SearchBuilder();
    const defaultQuery = [
      builder.push(["supplier", "true"]).and().push(["active", "true"]).build(),
    ];
    const copy = { ...params };
    if (copy.search) {
      defaultQuery.unshift(copy.search);
    }
    params.search = defaultQuery.join(builder.AND);
    return contactServices.getListContactData(params);
  };

  /**
   * @deprecated
   * use {@linkcode toOptions}
   */
  const toOptionsName = (data: ResponseListContactData) => {
    return useMapContactToOption(data);
  };

  const toOptions = (
    data: Array<ListContactDataDto>
  ): Array<Option<ListContactDataDto>> => {
    return data.map(item => ({
      key: item.id,
      value: item.id,
      label: transformContactName(item.firstName, item.fullName),
    }));
  };

  /**
   * @deprecated
   * new hook {@linkcode findOne}
   */
  const findById = (id: string) => {
    return contactServices.getContactData(id);
  };

  const findOne = (id: string): Promise<DetailContactDataDto> => {
    return contactServices.getContactDetail(id);
  };

  const toBankOptions = (data: ResponseContactData): Option[] => {
    return data.bankDataList.map<Option>((item, i) => ({
      label: `${item.bankAccName} - ${item.bankAccNumber}`,
      value: `${item.bankAccName} - ${item.bankAccNumber}`,
      key: i,
    }));
  };

  const toShipToAddressOptions = (
    data: AddressDataDto[]
  ): Option<AddressDataDto>[] => {
    const filtered = data.filter(item => item.shipTo);
    return filtered.map<Option<AddressDataDto>>((item, i) => ({
      label: item.address,
      value: item.address,
      key: i,
      meta: item,
    }));
  };

  const toBillToAddressOptions = (
    data: AddressDataDto[]
  ): Option<AddressDataDto>[] => {
    const filtered = data.filter(item => item.billTo);
    return filtered.map<Option<AddressDataDto>>((item, i) => ({
      label: item.address,
      value: item.address,
      key: i,
      meta: item,
    }));
  };

  const getDefaultShipToAddress = (data: Array<AddressDataDto>): string => {
    const address = data.find(el => el.shipTo && el.primaryShipTo);
    if (address) return address.address;
    return "";
  };

  const getDefaultBillToAddress = (data: Array<AddressDataDto>): string => {
    const address = data.find(el => el.billTo && el.primaryBillTo);
    if (address) return address.address;
    return "";
  };

  const toPicNameOptions = (
    data: Array<AddressDataDto>
  ): Array<Option<AddressDataLineDto>> => {
    const filtered = data.filter(item => item.shipTo);
    const picNames: Option<AddressDataLineDto>[] = [];
    filtered.forEach(item => {
      item.addressDataLines?.forEach(item2 => {
        picNames.push({
          label: item2.picName,
          value: item2.picName,
          key: new Date().valueOf(), // unique key
          meta: item2,
        });
      });
    });

    return picNames;
  };

  const findAllSupplierType = (): Promise<ResponseListMaster[]> => {
    return useFindMasterType("SUPPLIER_TYPE");
  };

  const filterBy = (
    field: Partial<{
      firstName: string;
      lastName: string;
    }>
  ) => {
    const { firstName, lastName } = field;
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (firstName) {
      q.push(builder.push(["firstName", firstName], { like: "both" }).build());
    }

    if (lastName) {
      q.push(builder.push(["lastName", lastName], { like: "both" }).build());
    }

    return q.join(builder.OR);
  };

  const toContactLocationOptions = (
    data: Array<AddressDataDto>
  ): Array<Option<AddressDataDto>> => {
    const options = data.map((item, i) => ({
      key: i,
      label: item.location || "-",
      value: item.location || "",
      name: item.location || "-",
      meta: item,
    }));

    return removeDuplicateObj(options, "value");
  };

  const flatContactName = (name: string) => {
    return name.replace(",", "").trim();
  };

  return {
    toContactLocationOptions,
    findAllEmployee,
    filterBy,
    toBankOptions,
    findAllCustomerAndSupplier,
    findSuppliers,
    toOptionsCode,
    toOptionsName,
    findCustomers,
    findById,
    toShipToAddressOptions,
    toBillToAddressOptions,
    getDefaultShipToAddress,
    getDefaultBillToAddress,
    toPicNameOptions,
    findSales,
    toOptionsNameCode,
    findAllSupplierType,
    findOne,
    toSupplierOptions,
    findAllDealer,
    findAllLessor,
    findAllInsurance,
    buildQueryFindEmployee,
    findAllMechanic,
    transformContactName,
    toOptions,
    toOptionsCustomer,
    flatContactName,
    findAll,
    create,
    update,
  };
};

export {
  useContactData,
  useContactListFindById,
  useFindAllActiveCustomer,
  useMapContactToOption,
};
