import { SearchBuilder } from "@/builder";
import { Option } from "@/models/class/option.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import {
  WarehouseCreateRequestDto,
  WarehouseCreateResponseDto,
  WarehouseResponseDto,
} from "@interface/warehouse";

export interface FormValue extends WarehouseCreateRequestDto {
  branchName: string;
}

export type SearchField = Partial<{
  name: string;
  branchName: string;
  address: string;
}>;

const useWarehouse = () => {
  const update = (
    warehouseId: string,
    body: WarehouseCreateRequestDto
  ): Promise<WarehouseResponseDto> => {
    return logisticServices.updateWarehouse(body, warehouseId);
  };

  const create = (
    body: WarehouseCreateRequestDto
  ): Promise<WarehouseCreateResponseDto> => {
    return logisticServices.createWarehouse(body);
  };

  const findById = (id: string) => {
    return logisticServices.getDetailWarehouse(id);
  };

  const findAll = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<WarehouseResponseDto>> => {
    return logisticServices.getListWarehouse(params);
  };

  const toOptions = (
    data: Array<WarehouseResponseDto>
  ): Array<Option<WarehouseResponseDto>> => {
    return data.map(item => ({
      label: `${item.branchWarehouse.name}, ${item.name}`,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const searchBy = ({
    name = "",
    branchName = "",
    address = "",
  }: SearchField): string => {
    const builder = new SearchBuilder();
    const queries: Array<string> = [];

    if (name) {
      queries.push(builder.push(["name", name], { like: "both" }).build());
    }

    if (branchName) {
      queries.push(
        builder
          .push(["branchWarehouse.name", branchName], { like: "both" })
          .build()
      );
    }

    if (address) {
      queries.push(
        builder.push(["address", address], { like: "both" }).build()
      );
    }

    return queries.join(builder.OR);
  };

  const initCreateDto = (): WarehouseCreateRequestDto => ({
    address: "",
    branchWarehouseId: "",
    name: "",
  });

  const initForm = (): FormValue => ({
    address: "",
    branchWarehouseId: "",
    branchName: "",
    name: "",
  });

  const mapFormToCreateDto = ({
    address,
    branchWarehouseId,
    name,
  }: FormValue): WarehouseCreateRequestDto => {
    const dto = initCreateDto();

    dto.address = address;
    dto.branchWarehouseId = branchWarehouseId;
    dto.name = name;

    return dto;
  };

  const mapDetailToForm = (dto: WarehouseResponseDto): FormValue => {
    const {
      address,
      name,
      branchWarehouse: { id: branchId, name: branchName },
    } = dto;
    const form = initForm();

    form.address = address;
    form.name = name;
    form.branchName = branchName;
    form.branchWarehouseId = branchId;

    return form;
  };

  return {
    findById,
    findAll,
    toOptions,
    searchBy,
    create,
    update,
    initCreateDto,
    initForm,
    mapFormToCreateDto,
    mapDetailToForm,
  };
};

export default useWarehouse;
