// Models
import { contactEditLoader } from "@/loader/ContactLoader";
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const index: RouteConfig = {
  path: config.CONTACT_ROUTE.path,
  name: config.CONTACT_ROUTE.name,
  meta: config.CONTACT_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.CONTACT_ROUTE_LIST.path,
      name: config.CONTACT_ROUTE_LIST.name,
      meta: config.CONTACT_ROUTE_LIST.meta,
      component: () => import(/* webpackPrefetch: true */ "./IndexPage.vue"),
    },
    {
      path: config.CONTACT_ROUTE_CREATE.path,
      name: config.CONTACT_ROUTE_CREATE.name,
      meta: config.CONTACT_ROUTE_CREATE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ContactCreatePage.vue"),
    },
    {
      path: config.CONTACT_ROUTE_EDIT.path,
      name: config.CONTACT_ROUTE_EDIT.name,
      meta: config.CONTACT_ROUTE_EDIT.meta,
      beforeEnter: contactEditLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./ContactEditPage.vue"),
    },
    {
      path: config.CONTACT_ROUTE_DETAIL.path,
      name: config.CONTACT_ROUTE_DETAIL.name,
      meta: config.CONTACT_ROUTE_DETAIL.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ContactDetailPage.vue"),
    },
  ],
};

export default index;
