import { generateUUID } from "@/helpers/uuid";
import usePreferences from "@/hooks/usePreferences";
import SALES_ORDER_STATUS from "@/models/enums/sales-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  TruckingSalesOrderFormDto,
  TruckingSalesOrderLineFormDtoRow,
} from "@/models/interface/trucking-sales-order";

export function buildSalesOrderFormDto(): TruckingSalesOrderFormDto {
  const { findBaseCurrency } = usePreferences();
  const baseCurrency = findBaseCurrency();
  const defaultCurrency = baseCurrency?.name ?? "";

  return {
    documentNumber: undefined,
    currency: {
      key: defaultCurrency,
      label: defaultCurrency,
    },
    date: null,
    notes: "",
    salesOrderLines: [],
    status: "" as SALES_ORDER_STATUS,
    taxCalculation: {
      key: TAX_CALCULATION.EXCLUSIVE,
      label: TAX_CALCULATION.EXCLUSIVE,
    },
    termOfPayment: 0,
    currencyRate: 1,
    documentReference: "",
    deletedSalesOrderLineIds: [],
    billingAddress: undefined,
    branch: undefined,
    customer: undefined,
    shippingAddress: undefined,
  };
}

export function buildTruckingSalesOrderLineFormDto(): TruckingSalesOrderLineFormDtoRow {
  return {
    description: "",
    rowId: generateUUID(),
    id: null,
    equipment: "",
    brand: "",
    discount: 0,
    documentReference: "",
    dpp: 0,
    price: 0,
    qty: 0,
    salesName: "",
    serialNumber: "",
    specification: "",
    subtotal: 0,
    taxAmount: 0,
    type: "",
    unitOfMeasurement: "",
    key: 0,
    taxRate: 0,
    unitOfMeasurementId: "",
    unitCode: undefined,
    taxCode: undefined,

    loadingTaxCodes: false,
    loadingTrucks: false,
    taxCodeOptions: [],
    truckOptions: [],
  };
}
