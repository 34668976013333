import { ONE } from "@/models/constant/global.constant";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  ReceivingItemAssetRequestDto,
  ReceivingItemCreateRequestDto,
  ReceivingItemDraftResponseDto,
  ReceivingItemLineDraftResponseDto,
  ReceivingItemLineRequestDto,
  ReceivingItemResponseDto,
  ReceivingItemSubmitResponseDto,
  ReceivingItemUpdateRequestDto,
} from "@/models/interface/receive-item";
import { receiveItemService } from "@/services/ReceiveItem.service";
import store from "@/store";
import {
  FormValue as FormChecklist,
  ReceivingItemRow as GrChecklistRow,
} from "@/store/goodsReceiptChecklist.store";
import {
  FormValue as FormPrice,
  ReceiveItemRow as GrPriceRow,
  State as GrPriceState,
} from "@/store/goodsReceiptPrice.store";
import {
  initFormLine,
  initFormValue,
} from "@/store/resources/GoodsReceiptChecklist.resource";
import {
  initFormRow as initRowPrice,
  initFormValue as initFormPrice,
} from "@/store/resources/GoodsReceiptPrice.resource";
import moment from "moment";

const useReceiveItem = () => {
  const initItemAssetDto = (): ReceivingItemAssetRequestDto => ({
    capacity: "",
    serialNumber: "",
    specification: "",
    type: "",
  });

  const initReceivingItemCreateRequestDto =
    (): ReceivingItemCreateRequestDto => ({
      supplierId: "",
      purchaseOrderId: "",
      receiveDate: "",
      branchWarehouseId: "",
      description: "",
      supplierDeliveryOrderNo: "",
      supplierShipToAddress: "",
      supplierBillToAddress: "",
      status: LogisticReceivingItemStateEnum.DRAFT,
      isCheck: false,
      termOfPayment: 0,
      currencyCode: "",
      currencyRate: ONE,
      taxType: null,
      receiveItems: [],
      totalDiscount: 0,
      totalTax: 0,
      totalProductPrice: 0,
      grandTotal: 0,
    });

  const initReceivingItemLineRequestDto = (): ReceivingItemLineRequestDto => ({
    discountValue: 0,
    id: "",
    itemAssets: [],
    locationReceivedId: "",
    merk: "",
    partNumber: "",
    percentDiscount: 0,
    price: 0,
    productId: "",
    productUomId: "",
    purchaseOrderLineId: "",
    qty: 0,
    qtyPO: 0,
    serialNumbers: [],
    tax: 0,
    taxBase: 0,
    taxCodeId: "",
    totalPrice: 0,
    trackAsAsset: false,
  });

  const createDraft = (
    payload: ReceivingItemCreateRequestDto
  ): Promise<ReceivingItemCreateRequestDto> => {
    return receiveItemService.createReceiveItemAsDraft(payload);
  };

  const create = (
    payload: ReceivingItemCreateRequestDto
  ): Promise<ReceivingItemSubmitResponseDto> => {
    return receiveItemService.createReceiveItem(payload);
  };

  /**
   * @description
   * map receiving item row to receiving item line request dto
   *
   * **used only for GR CHECKLIST**
   */
  const mapRowChecklistToLine = (
    row: GrChecklistRow
  ): ReceivingItemLineRequestDto => {
    const req: ReceivingItemLineRequestDto = initReceivingItemLineRequestDto();

    req.discountValue = row.discountValue || 0;
    req.id = row.id || "";
    req.itemAssets = row.itemAssets || [];
    req.locationReceivedId = row.locationReceived?.key || "";
    req.merk = row.merk || "";
    req.partNumber = row.partNumber || "";
    req.price = row.price || 0;
    req.productId = row.productId || "";
    req.productUomId = row.productUomId || "";
    req.purchaseOrderLineId = row.purchaseOrderLineId || "";
    req.qty = row.qty || 0;
    req.qtyPO = row.qtyPO || 0;
    req.taxCodeId = row.taxCodeId || "";
    req.trackAsAsset = row.trackAsAsset;

    delete req.percentDiscount;
    delete req.tax;
    delete req.taxBase;
    delete req.totalPrice;

    return req;
  };

  const mapFormChecklistToCreateDto = (
    form: FormChecklist
  ): ReceivingItemCreateRequestDto => {
    const req: ReceivingItemCreateRequestDto =
      initReceivingItemCreateRequestDto();
    req.supplierId = form.supplier?.key || "";
    req.purchaseOrderId = form.purchaseOrderId || "";
    req.receiveDate = form.receiveDate?.format() || "";
    req.branchWarehouseId = form.branchWarehouseId || "";
    req.description = form.description || "";
    req.supplierDeliveryOrderNo = form.supplierDeliveryOrderNo || "";
    req.supplierBillToAddress = form.supplierBillToAddress || "";
    req.isCheck = true;
    req.taxType = form.taxType;
    req.receiveItems = form.receiveItems.map<ReceivingItemLineRequestDto>(
      mapRowChecklistToLine
    );

    delete req.supplierShipToAddress;
    delete req.termOfPayment;
    delete req.currencyCode;
    delete req.currencyRate;

    return req;
  };

  const updateReceiveItem = (
    id: string,
    payload: ReceivingItemUpdateRequestDto
  ): Promise<ReceivingItemResponseDto> => {
    return receiveItemService.updateReceiveItem(payload, id);
  };

  const initReceivingItemUpdateRequestDto =
    (): ReceivingItemUpdateRequestDto => ({
      supplierId: "",
      purchaseOrderId: "",
      receiveDate: "",
      branchWarehouseId: "",
      description: "",
      supplierDeliveryOrderNo: "",
      supplierShipToAddress: "",
      supplierBillToAddress: "",
      termOfPayment: 0,
      currencyCode: "",
      currencyRate: ONE,
      receiveItems: [],
      deletedReceiveItemIds: [],
    });

  const mapFormChecklistToUpdateDto = (
    form: FormChecklist
  ): ReceivingItemUpdateRequestDto => {
    const req: ReceivingItemUpdateRequestDto =
      initReceivingItemUpdateRequestDto();
    req.supplierId = form.supplier?.key || "";
    req.purchaseOrderId = form.purchaseOrderId;
    req.receiveDate = form.receiveDate?.format() || "";
    req.branchWarehouseId = form.branchWarehouseId;
    req.description = form.description;
    req.supplierDeliveryOrderNo = form.supplierDeliveryOrderNo;
    req.supplierBillToAddress = form.supplierBillToAddress;
    req.receiveItems = form.receiveItems.map<ReceivingItemLineRequestDto>(
      mapRowChecklistToLine
    );
    req.deletedReceiveItemIds = form.deletedReceiveItemIds || [];

    delete req.currencyCode;
    delete req.currencyRate;
    delete req.supplierShipToAddress;
    delete req.termOfPayment;

    return req;
  };

  const submitReceiveItem = (
    id: string,
    payload: ReceivingItemUpdateRequestDto
  ): Promise<ReceivingItemSubmitResponseDto> => {
    return receiveItemService.submitReceiveItem(id, payload);
  };

  const printGrChecklistReport = (id: string): Promise<Blob> => {
    return receiveItemService.printGrChecklistReport(id);
  };

  const findDraftById = (
    id: string
  ): Promise<ReceivingItemDraftResponseDto> => {
    return receiveItemService.getDetailDraftReceiveItem(id);
  };

  const mapLineDraftToChecklistRow = (
    line: ReceivingItemLineDraftResponseDto
  ): GrChecklistRow => {
    const row: GrChecklistRow = initFormLine();
    row.id = line.id || "";
    row.purchaseOrderLineId = line.purchaseOrderLineId || "";
    row.productId = line.productId || "";
    row.productUomId = line.productUomId || "";
    row.partNumber = line.partNumber || "";
    row.qtyPO = line.qtyPO || 0;
    row.qty = line.qty || 0;
    row.price = line.price || 0;
    row.taxCodeId = line.taxCodeId || "";
    row.merk = line.merk || "";
    row.taxBase = line.taxBase || 0;
    row.percentDiscount = line.percentDiscount || 0;
    row.discountValue = line.discountValue || 0;
    row.tax = line.tax || 0;
    row.totalPrice = line.totalPrice || 0;
    row.serialNumbers = line.serialNumbers || [];
    row.itemAssets = line.itemAssets || [];
    row.trackAsAsset = line.trackAsAsset || false;
    row.locationReceived = {
      key: line.locationReceivedId,
      label: line.locationReceived,
    };
    row.productCode = line.productCode || "";
    row.productName = line.productName || "";
    row.productUom = line.productUom || "";
    row.source = "detail";
    return row;
  };

  const mapDetailDraftToForm = (
    detail: ReceivingItemDraftResponseDto
  ): FormChecklist => {
    const form = initFormValue();

    if (detail.supplierId) {
      form.supplier = { key: detail.supplierId, label: detail.supplierName };
    }
    form.purchaseOrderId = detail.purchaseOrderId || "";
    form.receiveDate = detail.receiveDate ? moment(detail.receiveDate) : null;
    form.branchWarehouseId = detail.branchWarehouseId || "";
    form.description = detail.description || "";
    form.supplierDeliveryOrderNo = detail.supplierDeliveryOrderNo || "";
    form.supplierShipToAddress = detail.supplierShipToAddress || "";
    form.supplierBillToAddress = detail.supplierBillToAddress || "";
    form.status = (detail.status || "") as LogisticReceivingItemStateEnum;
    form.isCheck = detail.isCheck || true;
    form.termOfPayment = detail.termOfPayment || 0;
    form.currencyCode = detail.currencyCode || "";
    form.currencyRate = detail.currencyRate || 0;
    form.taxType = (detail.taxType || "") as TAX_CALCULATION;
    form.receiveItems = detail.receiveItems.map<GrChecklistRow>(
      mapLineDraftToChecklistRow
    );
    form.totalDiscount = detail.totalDiscount || 0;
    form.totalTax = detail.totalTax || 0;
    form.totalProductPrice = detail.totalProductPrice || 0;
    form.grandTotal = detail.grandTotal || 0;
    form.branchWarehouse = detail.branchWarehouse || "";
    form.purchaseOrderNumber = detail.purchaseOrderNumber || "";
    form.checklistNumber = detail.checklistNumber || "";
    form.createdBy = detail.createdBy || "";

    return form;
  };

  const mapRowPriceToLine = (row: GrPriceRow): ReceivingItemLineRequestDto => {
    const line: ReceivingItemLineRequestDto = initReceivingItemLineRequestDto();

    line.discountValue = row.discountValue;
    line.id = row.id;
    line.itemAssets = row.itemAssets;
    line.locationReceivedId = row.locationReceived?.key ?? "";
    line.merk = row.merk;
    line.partNumber = row.partNumber;
    line.percentDiscount = row.percentDiscount;
    line.price = row.price;
    line.productId = row.productId;
    line.productUomId = row.productUomId;
    line.purchaseOrderLineId = row.purchaseOrderLineId;
    line.qty = row.qty;
    line.qtyPO = row.qtyPO;
    line.serialNumbers = row.serialNumbers;
    line.tax = row.tax;
    line.taxBase = row.taxBase;
    line.taxCodeId = row.taxCodeId;
    line.totalPrice = row.totalPrice;
    line.trackAsAsset = row.trackAsAsset;

    return line;
  };

  const mapFormPriceToCreateDto = (
    form: FormPrice
  ): ReceivingItemCreateRequestDto => {
    const req: ReceivingItemCreateRequestDto =
      initReceivingItemCreateRequestDto();

    req.branchWarehouseId = form.branch?.key ?? "";
    req.currencyCode = form.currencyCode;
    req.currencyRate = form.currencyRate;
    req.description = form.description;
    req.grandTotal = store.getters["goodsReceiptPriceStore/getGrandTotal"];
    req.isCheck = form.isCheck;
    req.purchaseOrderId = form.purchaseOrderId;
    req.receiveDate = form.receiveDate ? form.receiveDate.format() : "";
    req.receiveItems =
      form.receiveItems.map<ReceivingItemLineRequestDto>(mapRowPriceToLine);
    req.supplierBillToAddress = form.supplierBillToAddress;
    req.supplierDeliveryOrderNo = form.supplierDeliveryOrderNo;
    req.supplierId = form.supplierId;
    req.supplierShipToAddress = form.supplierShipToAddress;
    req.taxType = form.taxType;
    req.termOfPayment = form.termOfPayment;
    req.totalDiscount =
      store.getters["goodsReceiptPriceStore/getTotalDiscount"];
    req.totalProductPrice =
      store.getters["goodsReceiptPriceStore/getTotalBaseAmount"];
    req.totalTax = store.getters["goodsReceiptPriceStore/getTotalTax"];

    return req;
  };

  const mapLineDraftToPriceRow = (
    line: ReceivingItemLineDraftResponseDto
  ): GrPriceRow => {
    const row: GrPriceRow = initRowPrice();

    row.discountValue = line.discountValue || 0;
    row.id = line.id || "";
    row.itemAssets = line.itemAssets || "";
    row.locationReceived = {
      key: line.locationReceivedId,
      label: line.locationReceived,
    };
    row.merk = line.merk || "";
    row.partNumber = line.partNumber || "";
    row.percentDiscount = line.percentDiscount || 0;
    row.price = line.price || 0;
    row.productCode = line.productCode || "";
    row.productId = line.productId || "";
    row.productName = line.productName || "";
    row.productUom = line.productUom || "";
    row.productUomId = line.productUomId || "";
    row.purchaseOrderLineId = line.purchaseOrderLineId || "";
    row.qty = line.qty || 0;
    row.qtyOutstanding = line.qtyOutstanding || 0;
    row.qtyPO = line.qtyPO || 0;
    row.serialNumbers = line.serialNumbers || "";
    row.tax = line.tax || 0;
    row.taxBase = line.taxBase || 0;
    row.taxCode = line.taxCode || "";
    row.taxCodeId = line.taxCodeId || "";
    row.taxRate = line.taxRate || 0;
    row.totalPrice = line.totalPrice || 0;
    row.trackAsAsset = line.trackAsAsset || false;

    return row;
  };

  const mapDetailDraftToFormPrice = (
    detail: ReceivingItemDraftResponseDto
  ): FormPrice => {
    const form: FormPrice = initFormPrice();
    form.branch = {
      key: detail.branchWarehouseId,
      label: detail.branchWarehouse,
    };
    form.checklistNumber = detail.checklistNumber || "";
    form.currencyCode = detail.currencyCode || "";
    form.currencyDesc = detail.currencyDesc || "";
    form.currencyRate = detail.currencyRate || ONE;
    form.description = detail.description || "";
    form.grandTotal = detail.grandTotal || 0;
    form.isCheck = detail.isCheck || false;
    form.journalId = detail.journalId || "";
    form.journalNo = detail.journalNo || "";
    form.purchaseOrderId = detail.purchaseOrderId || "";
    form.purchaseOrderNumber = detail.purchaseOrderNumber || "";
    form.receiveDate = detail.receiveDate ? moment(detail.receiveDate) : null;
    form.receiveItems = detail.receiveItems.map(mapLineDraftToPriceRow);
    form.receiveNumber = detail.receiveNumber || "";
    form.status = detail.status || "";
    form.supplierBillToAddress = detail.supplierBillToAddress || "";
    form.supplierDeliveryOrderNo = detail.supplierDeliveryOrderNo || "";
    form.supplierId = detail.supplierId || "";
    form.supplierName = detail.supplierName || "";
    form.supplierShipToAddress = detail.supplierShipToAddress || "";
    form.taxType = detail.taxType;
    form.termOfPayment = detail.termOfPayment || 0;
    form.totalDiscount = detail.totalDiscount || 0;
    form.totalProductPrice = detail.totalProductPrice || 0;
    form.totalTax = detail.totalTax || 0;
    form.id = detail.id || "";
    form.createdBy = detail.createdBy || "";
    form.createdDate = detail.createdDate || "";
    form.modifiedBy = detail.modifiedBy || "";
    form.modifiedDate = detail.modifiedDate || "";

    return form;
  };

  const mapFormPriceToUpdateDto = (
    form: FormPrice
  ): ReceivingItemUpdateRequestDto => {
    const req: ReceivingItemUpdateRequestDto =
      initReceivingItemUpdateRequestDto();

    req.branchWarehouseId = form.branch?.key ?? "";
    req.currencyCode = form.currencyCode;
    req.currencyRate = form.currencyRate;
    req.description = form.description;
    req.purchaseOrderId = form.purchaseOrderId;
    req.receiveDate = form.receiveDate ? form.receiveDate.format() : "";
    req.receiveItems = form.receiveItems.map(mapRowPriceToLine);
    req.supplierBillToAddress = form.supplierBillToAddress;
    req.supplierDeliveryOrderNo = form.supplierDeliveryOrderNo;
    req.supplierId = form.supplierId;
    req.supplierShipToAddress = form.supplierShipToAddress;
    req.termOfPayment = form.termOfPayment;
    req.deletedReceiveItemIds = form.deletedReceiveItemIds || [];

    return req;
  };

  const isRowInvalid = (): boolean => {
    const state: GrPriceState = store.state["goodsReceiptPriceStore"];
    const rows: Array<GrPriceRow> = state.form.receiveItems;
    return !!rows.find(e => !e.locationReceived);
  };

  return {
    create,
    createDraft,
    findDraftById,
    initItemAssetDto,
    initReceivingItemCreateRequestDto,
    initReceivingItemLineRequestDto,
    initReceivingItemUpdateRequestDto,
    mapDetailDraftToForm,
    mapDetailDraftToFormPrice,
    mapFormChecklistToCreateDto,
    mapFormChecklistToUpdateDto,
    mapFormPriceToCreateDto,
    mapFormPriceToUpdateDto,
    mapLineDraftToChecklistRow,
    mapLineDraftToPriceRow,
    mapRowChecklistToLine,
    mapRowPriceToLine,
    printGrChecklistReport,
    submitReceiveItem,
    updateReceiveItem,
    isRowInvalid,
  };
};

export default useReceiveItem;
