import { ONE } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RoundingUtils } from "@/utils";
import Decimal from "decimal.js-light";

const useSalesOrderCalculation = () => {
  const calculate = (
    price: number,
    qty: number,
    discount: number,
    taxRate: number,
    taxCalculation: TAX_CALCULATION
  ) => {
    let taxAmount = 0;
    let dpp = 0;
    let subtotal = 0;

    const grossValue = RoundingUtils.round(
      new Decimal(price).times(qty),
      Decimal.ROUND_HALF_EVEN
    );
    const afterDiscount = RoundingUtils.round(
      new Decimal(grossValue).minus(discount),
      Decimal.ROUND_HALF_EVEN
    );

    if (taxCalculation === TAX_CALCULATION.EXCLUSIVE) {
      const tax = RoundingUtils.round(
        new Decimal(taxRate).dividedBy(100).times(afterDiscount),
        Decimal.ROUND_HALF_EVEN
      );

      dpp = afterDiscount.toNumber();
      taxAmount = tax.toNumber();
    } else if (taxCalculation === TAX_CALCULATION.INCLUSIVE) {
      const taxPercent = new Decimal(taxRate).dividedBy(100);
      const denumerator = taxPercent.plus(ONE);

      dpp = RoundingUtils.round(
        new Decimal(afterDiscount).dividedBy(denumerator),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
      taxAmount = RoundingUtils.round(
        new Decimal(afterDiscount).minus(dpp),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
    } else if (taxCalculation === TAX_CALCULATION.NONE) {
      dpp = afterDiscount.toNumber();
    }

    if (taxCalculation === TAX_CALCULATION.NONE) {
      subtotal = afterDiscount.toNumber();
    } else if (
      taxCalculation === TAX_CALCULATION.INCLUSIVE ||
      taxCalculation === TAX_CALCULATION.EXCLUSIVE
    ) {
      subtotal = RoundingUtils.round(
        new Decimal(dpp).plus(taxAmount),
        Decimal.ROUND_HALF_EVEN
      ).toNumber();
    }

    return {
      taxAmount,
      dpp,
      subtotal,
    };
  };

  return {
    calculate,
  };
};

export default useSalesOrderCalculation;
