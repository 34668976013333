import { generateUUID } from "@/helpers/uuid";
import { ONE } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import {
  TruckingAccountReceivableFormPrepaymentLineState,
  TruckingAccountReceivableFormPrepaymentState,
  TruckingAccountReceivableFormState,
  TruckingAccountReceivableFormStatusDetailInvoiceState,
  TruckingAccountReceivableFormStatusDetailState,
  TruckingAccountReceivableProductState,
} from "@interface/trucking-account-receivable";

export function buildTruckingAccountReceivableFormStatusDetailPrepaymentState(): TruckingAccountReceivableFormStatusDetailInvoiceState {
  return {
    invoiceDate: "",
    invoiceNumber: "",
    amount: 0,
    key: generateUUID(),
  };
}

export function buildTruckingAccountReceivableFormPrepaymentLineState(): TruckingAccountReceivableFormPrepaymentLineState {
  return {
    id: null,
    invoicePrepaymentId: "",
    invoicePrepaymentNumber: "",
    appliedAmount: 0,
    description: "",
  };
}

export function buildTruckingAccountReceivableFormPrepaymentState(): TruckingAccountReceivableFormPrepaymentState {
  return {
    deletedPrepaymentLineIds: [],
    prepaymentLines: [],
  };
}

export function buildTruckingAccountReceivableProductState(): TruckingAccountReceivableProductState {
  return {
    key: generateUUID(),
    docReference: "",
    docReferenceId: "",
    shipmentId: "",
    id: null,
    unitCode: undefined,
    serialNumber: "",
    equipment: "",
    brand: "",
    type: "",
    spec: "",
    salesPerson: undefined,
    price: 0,
    revenueAccount: undefined,
    baseAmount: 0,
    includePph: false,
    incomeTax: undefined,
    taxCode: undefined,
    taxAmount: 0,
    subtotal: 0,
    description: "",
    discountAmount: 0,
    discountPercent: 0,
    salesOrderLineId: null,
    generated: false,
    isSearchIncomeTax: false,
    isSearchRevenueAccount: false,
    isSearchSalesPerson: false,
    isSearchTaxCode: false,
    loadingIncomeTax: false,
    loadingRevenueAccount: false,
    loadingSalesPerson: false,
    loadingTaxCode: false,
    revenueAccountOptions: [],
    salesPersonOptions: [],
    taxCodeOptions: [],
    incomeTaxOptions: [],
    amountAfterProRateAdditionalDiscount: 0,
    grossAfterDiscount: 0,
    proRateAdditionalDiscountAmount: 0,
    proRateAmountAfterAdditionalDiscountRatio: 0,
    proRatePrepaymentAmount: 0,
    proRateRatio: 0,
    quantity: ONE,
    taxRate: 0,
    disabledTax: false,
    isSearchUnitCode: false,
    loadingUnitCode: false,
    unitCodeOptions: [],
  };
}

export function buildTruckingAccountReceivableFormStatusDetailState(): TruckingAccountReceivableFormStatusDetailState {
  return {
    total: 0,
    prepaymentUsed: 0,
    prepaymentLines: [],
    remainingInvoiceAmount: 0,
    creditMemoLines: [],
    creditMemoUsed: 0,
    returnAmount: 0,
    receivableAccount: "",
    invoiceJoinNo: "",
    invoiceReceipts: [],
  };
}

export function buildTruckingAccountReceivableFormState(): TruckingAccountReceivableFormState {
  const INVOICE_TRUCKING_SOURCE = "Trucking";
  return {
    invoiceType: "",
    invoiceSource: INVOICE_TRUCKING_SOURCE,
    invoiceDate: null,
    branch: undefined,
    currency: undefined,
    currencyRates: ONE,
    customer: undefined,
    shippingAddress: undefined,
    billingAddress: undefined,
    salesOrders: [],
    invoiceNumber: "",
    taxCalculation: TAX_CALCULATION.EXCLUSIVE,
    termOfPayment: undefined,
    receivableAccount: undefined,
    operatorName: "",
    assignee: undefined,
    description: "",
    status: "",
    customerTaxType: "",
    taxRegistrationName: "",
    taxRegistrationNumber: "",
    taxIsUploaded: false,
    taxInvoiceDate: null,
    taxInvoiceNumber: "",
    prepayment: buildTruckingAccountReceivableFormPrepaymentState(),
    statusDetail: buildTruckingAccountReceivableFormStatusDetailState(),
    products: [],
    subTotal: 0,
    discountAmount: 0,
    discountPercent: 0,
    totalTax: 0,
    totalPrepayment: 0,
    grandTotal: 0,
    accountingDate: null,
    deletedInvoiceARLines: [],
    journalId: "",
    journalNumber: "",
    totalGross: 0,
    totalUsedPrepayment: 0,
    customerGoodReceiptNo: null,
    customerPurchaseOrderNo: null,
  };
}
