import { displayNeg } from "@/helpers/common";
import {
  Row,
  useCalculator,
  useDeleteRows,
  useProduct,
  usePurchaseOrder,
  useReceiveItem,
} from "@/hooks";
import { Option } from "@/models/class/option.class";
import { ONE } from "@/models/constant/global.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ProductDetailDto } from "@/models/interface/master-product";
import {
  ReceivingItemAssetRequestDto,
  ReceivingItemCreateRequestDto,
  ReceivingItemDraftResponseDto,
  ReceivingItemLineDraftResponseDto,
  ReceivingItemLineRequestDto,
} from "@/models/interface/receive-item";
import {
  initFormRow,
  initFormValue,
} from "@/store/resources/GoodsReceiptPrice.resource";
import { LabelInValue } from "@/types";
import { Decimal } from "decimal.js-light";
import { Moment } from "moment";
import { ModalState } from "./goodsReceiptChecklist.store";
import { initDetailGrDraft } from "./resources/GoodsReceiptChecklist.resource";

export type ReceiveItemLineDetail = Pick<
  ReceivingItemLineDraftResponseDto,
  | "productName"
  | "productCode"
  | "productUom"
  | "qtyOutstanding"
  | "taxCode"
  | "taxRate"
>;
export type ReceiveItemRow = Row<
  Omit<ReceivingItemLineRequestDto, "locationReceivedId"> &
    ReceiveItemLineDetail & {
      locationReceived: LabelInValue | undefined;
      isSearchLocation: boolean;
      locationOptions: Option[];
      loadingLocation: boolean;
    },
  number
>;
export type ReceiveItemDetail = Pick<
  ReceivingItemDraftResponseDto,
  | "checklistNumber"
  | "currencyDesc"
  | "journalId"
  | "journalNo"
  | "purchaseOrderNumber"
  | "receiveNumber"
  | "supplierName"
  | "id"
  | "createdBy"
  | "createdDate"
  | "modifiedBy"
  | "modifiedDate"
>;
export type ReceiveItemCreateRequestWithoutLine = Omit<
  ReceivingItemCreateRequestDto,
  "receiveItems" | "receiveDate" | "branchWarehouseId"
>;
export type FormValue = ReceiveItemCreateRequestWithoutLine &
  ReceiveItemDetail & {
    receiveItems: Array<ReceiveItemRow>;
    receiveDate: Moment | null;
    deletedReceiveItemIds?: Array<string>;
    branch: LabelInValue | undefined;
  };

export type State = {
  form: FormValue;
  modalState: ModalState;
  detail: ReceivingItemDraftResponseDto;
};

const state: State = {
  form: initFormValue(),
  modalState: {
    data: [],
    visible: false,
    toggle() {
      this.visible = !this.visible;
    },
  },
  detail: initDetailGrDraft(),
};

const getters = {
  getForm: (st: State): FormValue => {
    return st.form;
  },
  getTotalDiscount: (st: State): number => {
    const { sum } = useCalculator();
    const discount: Array<number> = st.form.receiveItems.map(
      e => e.discountValue
    );
    return displayNeg(sum(discount));
  },
  getTotalBaseAmount: (st: State): number => {
    const { sum } = useCalculator();
    const discount: Array<number> = st.form.receiveItems.map(
      e => e.taxBase || 0
    );
    return displayNeg(sum(discount));
  },
  getTotalTax: (st: State): number => {
    const { sum } = useCalculator();
    const discount: Array<number> = st.form.receiveItems.map(e => e.tax || 0);
    return displayNeg(sum(discount));
  },
  getGrandTotal: (st: State, getters): number => {
    const totalBaseAmount: number = getters.getTotalBaseAmount || 0;
    const totalTax: number = getters.getTotalTax || 0;
    const returnVal = new Decimal(totalBaseAmount).plus(totalTax);
    return displayNeg(returnVal.toNumber());
  },
  getDetail: (st: State): ReceivingItemDraftResponseDto => {
    return st.detail;
  },
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = { ...st.form };
    st.form = {
      ...copy,
      ...payload,
    };
  },
  setDetail: (st: State, payload: ReceivingItemDraftResponseDto): void => {
    st.detail = payload;
  },
};

const actions = {
  resetStore: (context): void => {
    const { commit } = context;
    const form: FormValue = initFormValue();
    const detail: ReceivingItemDraftResponseDto = initDetailGrDraft();
    commit("setForm", form);
    commit("setDetail", detail);
  },
  toggleModalAsset: (
    context,
    payload: Array<ReceivingItemAssetRequestDto>
  ): void => {
    const { state } = context;
    const local: State = state;
    local.modalState.data = payload;
    local.modalState.toggle();
  },
  autofillFromPo: (context, poId: string): void => {
    const { commit, dispatch } = context;
    const { findById } = usePurchaseOrder();
    findById(poId).then(res => {
      const form: Partial<FormValue> = {
        supplierName: res.supplierName || "",
        supplierId: res.supplierId || "",
        supplierBillToAddress: res.supplierBillToAddress || "",
        currencyCode: res.currency || "",
        currencyRate: res.currencyRate || ONE,
        branch: {
          key: res.branchWarehouseId,
          label: res.branchWarehouseName,
        },
        description: res.description || "",
        taxType: res.taxType,
        receiveItems:
          res.purchaseOrderLines?.map((item, i) => {
            const row: ReceiveItemRow = initFormRow();

            row.discountValue = item.discountValue || 0;
            row.itemAssets = item.itemAssets;
            row.key = i;
            row.merk = item.merk || "";
            row.partNumber = item.productCode || "";
            row.price = item.price || 0;
            row.productCode = item.productCode || "";
            row.productId = item.productId || "";
            row.productName = item.productName || "";
            row.productUom = item.uom || "";
            row.productUomId = item.uomId || "";
            row.purchaseOrderLineId = item.id || "";
            row.qty = item.qtyOutstanding || 0;
            row.qtyPO = item.qtyOutstanding || 0;
            row.tax = item.taxValue || 0;
            row.taxRate = item.taxRate || 0;
            row.taxBase = item.baseAmount || 0;
            row.taxCode = item.taxCode || "";
            row.taxCodeId = item.taxCodeId || "";
            row.totalPrice = item.subTotal || 0;

            return row;
          }) || [],
      };

      commit("setForm", form);
      dispatch("setDefaultLocationReceiveRow");
      dispatch("calculateLines");
    });
  },
  setDefaultLocationReceiveRow: (context): void => {
    const { state } = context;
    const { findById } = useProduct();
    const local: State = state;
    const promises: Promise<ProductDetailDto>[] = local.form.receiveItems.map(
      item => findById(item.productId)
    );
    Promise.all(promises).then(responses => {
      responses.forEach(({ locationReceiveId, locationReceiveName }, index) => {
        if (locationReceiveId) {
          local.form.receiveItems[index].locationReceived = {
            label: locationReceiveName,
            key: locationReceiveId,
          };
        }
      });
    });
  },
  removeLine: (context, rowKeys: Array<number>): void => {
    const { state, commit, dispatch } = context;
    const local: State = state;
    const { newSource, deletedRows } = useDeleteRows(
      local.form.receiveItems,
      rowKeys
    );
    const deletedIds: Array<string> = deletedRows
      .filter(e => !!e.id)
      .map<string>(e => e.id);
    const form: Partial<FormValue> = {
      receiveItems: newSource,
      deletedReceiveItemIds: deletedIds,
    };
    commit("setForm", form);
    dispatch("calculateLines");
  },
  calculateLines: (context): void => {
    const { state } = context;
    const { toRoundHalfUp } = useCalculator();
    const local: State = state;
    const isExcl: boolean = local.form.taxType === TAX_CALCULATION.EXCLUSIVE;
    const isIncl: boolean = local.form.taxType === TAX_CALCULATION.INCLUSIVE;
    local.form.receiveItems.forEach(row => {
      const gross = new Decimal(row.qty || 0).times(row.price || 0);
      const grossAftDisc = gross.minus(row.discountValue || 0);
      const taxRate = new Decimal(row.taxRate).dividedBy(100);
      let dpp = new Decimal(0); // nilai dasar pengenaan pajak
      let taxAmount = new Decimal(0); // nominal pajak dibayarkan

      if (isExcl) {
        dpp = grossAftDisc;
        taxAmount = dpp.times(taxRate);
      } else if (isIncl) {
        dpp = grossAftDisc.dividedBy(taxRate.plus(ONE));
        taxAmount = grossAftDisc.minus(dpp);
      }

      row.taxBase = toRoundHalfUp(dpp).toNumber();
      row.tax = toRoundHalfUp(taxAmount).toNumber();

      const totalPrice = dpp.plus(taxAmount);
      row.totalPrice = toRoundHalfUp(totalPrice).toNumber();
    });
  },
  mapDetailDraftToForm: (
    context,
    payload: ReceivingItemDraftResponseDto
  ): void => {
    const { commit } = context;
    const { mapDetailDraftToFormPrice } = useReceiveItem();
    const form: FormValue = mapDetailDraftToFormPrice(payload);
    commit("setForm", form);
    commit("setDetail", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
