import {
  toTitlecase as toTitle,
  trimSpaceToUnderscore
} from "@/helpers/common";
import { MasterTypeQueryParams } from "@/models/class/master-type-query-params.class";
import { Option } from "@/models/class/option.class";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { masterServices } from "@/services/master.service";

export const useFindMasterType = async (
  name: string
): Promise<ResponseListMaster[]> => {
  const params = new MasterTypeQueryParams(name);
  return await masterServices.listMaster(params);
};

export const useMapMasterTypeToOption = (
  source: ResponseListMaster[],
  toTitleCase?: boolean
): Option[] => {
  return source.map<Option>(el => ({
    label: toTitleCase ? toTitle(el.value) : el.value,
    value: trimSpaceToUnderscore(el.value),
    key: el.id,
  }));
};

export const useMapMasterTypeToOptionAlt = (
  source: Array<ResponseListMaster>
): Array<Option> => {
  return source.map<Option>(el => ({
    label: toTitle(el.value),
    value: el.value,
    key: el.id,
  }));
};

export const useFindMasterTaxType = async (): Promise<ResponseListMaster[]> => {
  return masterServices.listMaster({ name: "TAX_CALCULATION" });
};

export const useFindMasterTop = async (): Promise<ResponseListMaster[]> => {
  return masterServices.listMaster({ name: "TOP" });
};
