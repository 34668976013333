import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// get authorities helpers
// Models
import { config } from "@/models/constant/routes.constant";

// Containers
import AppContainer from "@/components/AppContainer/AppContainer.vue";

// Auth Routes
import AuthRoutes from "@/views/auth/index.routes";

// Error Routes
import ErrorRoutes from "@/views/error-page/index.routes";

// Not Authorize Routes
import Notauthorized from "@/views/notauthorized-page/index.routes";
// Dashboard Routes
import DashboardRoutes from "@/views/dashboard/index.routes";
// User Routes
import UserRoutes from "@/views/user-management/index.routes";
// Contact Data
import ContactData from "@/views/contact-data/index.routes";
// Setup
import AccountPayables from "@/views/account-payables/index.routes";
import AccountReceivables from "@/views/account-receivables/index.routes";
import Settings from "@/views/settings/index.routes";
import Uploader from "@/views/upload-data/index.routes";

import { layoutLoader } from "@/loader/LayoutLoader";
import { Mutations } from "@/models/constant/enums/mutations.enum";
import Accounting from "@/views/accounting/index.routes";
import Assets from "@/views/assets/index.routes";
import CashManagement from "@/views/cash-management/index.routes";
import GeneralJournal from "@/views/general-journal/index.routes";
import GeneralLedger from "@/views/general-ledger/index.routes";
import Inventory from "@/views/logistic/index.routes";
import Profile from "@/views/profile/index.routes";
import Purchasing from "@/views/purchasing/index.routes";
import Sales from "@/views/sales/index.routes";
import Trucking from "@/views/trucking/index.routes";

// QR Page
import QR from "@/views/pageQRCode/index.routes";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: config.MAIN_ROUTE.path,
    redirect: config.MAIN_ROUTE.redirect,
    name: config.MAIN_ROUTE.name,
    component: AppContainer,
    meta: config.MAIN_ROUTE.meta,
    beforeEnter: layoutLoader,
    children: [
      DashboardRoutes,
      UserRoutes,
      ContactData,
      Settings,
      Uploader,
      AccountReceivables,
      Assets,
      Inventory,
      Sales,
      GeneralJournal,
      Purchasing,
      CashManagement,
      AccountPayables,
      Accounting,
      GeneralLedger,
      Profile,
      Trucking,
    ],
  },
  AuthRoutes,
  QR,
  ErrorRoutes,
  Notauthorized,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  const state = store.state;
  // set loading component
  store.commit(Mutations.SET_LOADING_COMPONENT, true);
  if (to.meta.need_auth) {
    if (!state.access_token) {
      next({ path: config.SIGNIN_ROUTE.url });
    } else {
      next();
    }
  } else if (to.name === "SignIn" && state.access_token) {
    next({ path: config.DASHBOARD_ROUTE.url });
  } else {
    next();
  }
});

router.afterEach(() => {
  store.commit(Mutations.SET_LOADING_COMPONENT, false);
});

export default router;
