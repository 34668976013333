import SearchBuilder from "@/builder/SearchBuilder";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AssetStateEnum } from "@/models/enums/master-asset.enum";
import {
  DataListAssetBook,
  DataListMasterAsset,
  RequestParamDownloadAssetDepreciationProjection,
} from "@/models/interface/assets.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  AssetLeasingResponseDto,
  AssetResponseDto,
} from "@/models/interface/master-asset";
import { assetsServices } from "@/services/assets.service";
import { useFindMasterType } from "./master-type";

const useAsset = () => {
  const toggleAssetActive = (id: string) => {
    return assetsServices.toggleAssetActive(id);
  };

  const findAllAssetRent = (params: RequestQueryParamsModel) => {
    return assetsServices.listMasterAssetRent(params);
  };

  /**
   * @deprecated
   * new hooks {@linkcode findAllAsset}
   */
  const findAllMasterAsset = (params: RequestQueryParamsModel) => {
    return assetsServices.listMasterAsset(params);
  };

  const findAllAsset = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetResponseDto>> => {
    return assetsServices.getListMasterAsset(params);
  };

  const findAllAssetForLeasing = (
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AssetLeasingResponseDto>> => {
    return assetsServices.getAssetLeasing(params);
  };

  /**
   * @deprecated
   * new hooks {@linkcode toOptionsNew}
   */
  const toOptions = (
    source: Array<DataListMasterAsset>
  ): Array<Option<DataListMasterAsset>> => {
    return source.map<Option<DataListMasterAsset>>(item => ({
      label: item.unitCode,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  };

  const toOptionsNew = (
    data: Array<AssetResponseDto>
  ): Array<Option<AssetResponseDto>> => {
    return data.map(item => ({
      label: item.unitCode,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  };

  const toAssetLeasingOptions = (
    data: Array<AssetLeasingResponseDto>
  ): Array<Option<AssetLeasingResponseDto>> => {
    return data.map(item => ({
      label: item.unitCode,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  };

  const mapAssetRentToOptions = (data: AssetResponseDto[]): Option[] => {
    return data.map<Option<AssetResponseDto>>(item => ({
      label: item.unitCode,
      key: item.id,
      value: item.id,
      meta: item,
    }));
  };

  const findAllAssetBook = (params: RequestQueryParamsModel) => {
    return assetsServices.listAssetBook(params);
  };

  const mapAssetBookToOption = (
    data: Array<DataListAssetBook>
  ): Array<Option> => {
    return data.map<Option<DataListAssetBook>>(item => ({
      label: item.name,
      value: item.id,
      key: item.id,
    }));
  };

  const downloadAssetDepreciationProjection = (
    params: RequestParamDownloadAssetDepreciationProjection
  ) => {
    return assetsServices.downloadReportAssetDepreciationProjection(params);
  };

  const downloadCommercialReports = (
    params: RequestParamDownloadAssetDepreciationProjection
  ) => {
    return assetsServices.downloadCommercialReports(params);
  };

  const filterBy = (
    field: Partial<{ poNo: string; unitCode: string; assetNo: string }>
  ): string => {
    const builder = new SearchBuilder();
    const q: string[] = [];

    if (field.poNo) {
      q.push(
        builder
          .push(["purchaseOrderNumber", field.poNo], { like: "both" })
          .build()
      );
    }

    if (field.assetNo) {
      q.push(
        builder.push(["assetNo", field.assetNo], { like: "both" }).build()
      );
    }

    if (field.unitCode) {
      q.push(
        builder.push(["unitCode", field.unitCode], { like: "both" }).build()
      );
    }

    return q.join(builder.OR);
  };

  const findAllAssetStatus = () => {
    return useFindMasterType("ASSET_STATUS");
  };

  const findAllAvailableAssetSo = (params?: RequestQueryParams) => {
    return assetsServices.listMasterAssetAvailableSo(params);
  };

  const toOptionsRemastered = (
    list: AssetResponseDto[]
  ): Option<AssetResponseDto>[] => {
    return list.map(item => ({
      label: item.unitCode,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const findAllTruck = (
    params: RequestQueryParamsModel = new RequestQueryParams()
  ) => {
    const TRUCK = "truk";
    const defaultQuery: string[] = [
      new SearchBuilder()
        .push(["status", AssetStateEnum.RETIRED], { not: true })
        .and()
        .push(["assetCategory.categoryId", TRUCK], { like: "end" })
        .build(),
    ];

    if (params.search) {
      defaultQuery.unshift(params.search);
    }

    params.search = defaultQuery.join(SearchBuilder.AND);

    return findAllAsset(params);
  };

  return {
    findAllAssetStatus,
    filterBy,
    downloadCommercialReports,
    mapAssetBookToOption,
    findAllAssetBook,
    findAllAssetRent,
    mapAssetRentToOptions,
    downloadAssetDepreciationProjection,
    findAllMasterAsset,
    toOptions,
    findAllAvailableAssetSo,
    toOptionsRemastered,
    toOptionsNew,
    findAllAsset,
    findAllAssetForLeasing,
    toAssetLeasingOptions,
    findAllTruck,
    toggleAssetActive,
  };
};

export default useAsset;
