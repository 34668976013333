// Models
import { leasingCreateLoader, leasingDetailLoader } from "@/loader";
import {
  getDetailApInvoice,
  initApInvoiceForm,
} from "@/loader/ApInvoiceLoader";
import { config } from "@/models/constant/routes.constant";
import { RouteConfig } from "vue-router";

const router: RouteConfig = {
  path: config.ACCOUNT_PAYABLES_ROUTE.path,
  name: config.ACCOUNT_PAYABLES_ROUTE.name,
  meta: config.ACCOUNT_PAYABLES_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.CLOSEPERIODEPAYABLES_ROUTE.path,
      name: config.CLOSEPERIODEPAYABLES_ROUTE.name,
      meta: config.CLOSEPERIODEPAYABLES_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ClosePeriod.vue"),
    },
    {
      path: config.INVOICES_PREPAYMENT_DEBIT_MEMO_ROUTE.path,
      name: config.INVOICES_PREPAYMENT_DEBIT_MEMO_ROUTE.name,
      meta: config.INVOICES_PREPAYMENT_DEBIT_MEMO_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/ListInvoicesPrepaymentDebitMemo.vue"
        ),
    },
    {
      path: config.INVOICES_PREPAYMENT_DEBIT_MEMO_CREATE_ROUTE.path,
      name: config.INVOICES_PREPAYMENT_DEBIT_MEMO_CREATE_ROUTE.name,
      meta: config.INVOICES_PREPAYMENT_DEBIT_MEMO_CREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/CreateInvoicesPrepaymentDebitMemo.vue"
        ),
    },
    {
      path: config.INVOICES_PREPAYMENT_DEBIT_MEMO_EDIT_ROUTE.path,
      name: config.INVOICES_PREPAYMENT_DEBIT_MEMO_EDIT_ROUTE.name,
      meta: config.INVOICES_PREPAYMENT_DEBIT_MEMO_EDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./invoices-prepayment-debit-memo/CreateInvoicesPrepaymentDebitMemo.vue"
        ),
    },
    {
      path: config.LIST_INVOICES_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.LIST_INVOICES_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.LIST_INVOICES_ACCOUNT_PAYABLES_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoices-ap/IndexPage.vue"),
    },
    {
      path: config.CREATE_INVOICES_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.CREATE_INVOICES_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.CREATE_INVOICES_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: initApInvoiceForm,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoices-ap/CreateInvoiceAp.vue"),
    },
    {
      path: config.VIEW_INVOICES_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.VIEW_INVOICES_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.VIEW_INVOICES_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: getDetailApInvoice,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoices-ap/CreateInvoiceAp.vue"),
    },
    {
      path: config.EDIT_INVOICES_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.EDIT_INVOICES_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.EDIT_INVOICES_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: getDetailApInvoice,
      component: () =>
        import(/* webpackPrefetch: true */ "./invoices-ap/CreateInvoiceAp.vue"),
    },
    {
      path: config.LIST_LEASING_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.LIST_LEASING_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.LIST_LEASING_ACCOUNT_PAYABLES_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./leasing/IndexPage.vue"),
    },
    {
      path: config.CREATE_LEASING_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.CREATE_LEASING_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.CREATE_LEASING_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: leasingCreateLoader,
      component: () =>
        import(/* webpackPrefetch: true */ "./leasing/FormPage.vue"),
    },
    {
      path: config.DETAIL_LEASING_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.DETAIL_LEASING_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.DETAIL_LEASING_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: leasingDetailLoader,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./leasing/DetailPage.vue"),
    },
    {
      path: config.EDIT_LEASING_ACCOUNT_PAYABLES_ROUTE.path,
      name: config.EDIT_LEASING_ACCOUNT_PAYABLES_ROUTE.name,
      meta: config.EDIT_LEASING_ACCOUNT_PAYABLES_ROUTE.meta,
      beforeEnter: leasingDetailLoader,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./leasing/FormPage.vue"),
    },
    {
      path: config.LIST_AP_PAYMENTS_ROUTE.path,
      name: config.LIST_AP_PAYMENTS_ROUTE.name,
      meta: config.LIST_AP_PAYMENTS_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ap-payments/ListApPayments.vue"),
    },
    {
      path: config.CREATE_AP_PAYMENTS_ROUTE.path,
      name: config.CREATE_AP_PAYMENTS_ROUTE.name,
      meta: config.CREATE_AP_PAYMENTS_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./ap-payments/CreateApPayments.vue"
        ),
    },
    {
      path: config.EDIT_AP_PAYMENTS_ROUTE.path,
      name: config.EDIT_AP_PAYMENTS_ROUTE.name,
      meta: config.EDIT_AP_PAYMENTS_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./ap-payments/CreateApPayments.vue"
        ),
    },
    {
      path: config.VIEW_AP_PAYMENTS_ROUTE.path,
      name: config.VIEW_AP_PAYMENTS_ROUTE.name,
      meta: config.VIEW_AP_PAYMENTS_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./ap-payments/CreateApPayments.vue"
        ),
    },
    {
      path: config.PAYABLE_MUTATION_REPORT_ROUTE.path,
      name: config.PAYABLE_MUTATION_REPORT_ROUTE.name,
      meta: config.PAYABLE_MUTATION_REPORT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/PayableMutationReport.vue"
        ),
    },
    {
      path: config.PPHSUPPLIER_ROUTE.path,
      name: config.PPHSUPPLIER_ROUTE.name,
      meta: config.PPHSUPPLIER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/PphSupplier.vue"),
    },
    {
      path: config.AMORTIZE_ROUTE.path,
      name: config.AMORTIZE_ROUTE.name,
      meta: config.AMORTIZE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/Amortize.vue"),
    },
    {
      path: config.LEASINGTRACKING_ROUTE.path,
      name: config.LEASINGTRACKING_ROUTE.name,
      meta: config.LEASINGTRACKING_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/LeasingTracking.vue"),
    },
    {
      path: config.REPORT_KARTU_HUTANG_LEASING_ROUTE.path,
      name: config.REPORT_KARTU_HUTANG_LEASING_ROUTE.name,
      meta: config.REPORT_KARTU_HUTANG_LEASING_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/KartuHutangLeasing.vue"),
    },
    {
      path: config.AP_PAYMENT_REPORT_ROUTE.path,
      name: config.AP_PAYMENT_REPORT_ROUTE.name,
      meta: config.AP_PAYMENT_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ApPaymentReport.vue"),
    },
    {
      path: config.AGING_AP_REPORT_ROUTE.path,
      name: config.AGING_AP_REPORT_ROUTE.name,
      meta: config.AGING_AP_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/AgingApReport.vue"),
    },
    {
      path: config.LEASING_PAYMENT_PLAN_REPORT_ROUTE.path,
      name: config.LEASING_PAYMENT_PLAN_REPORT_ROUTE.name,
      meta: config.LEASING_PAYMENT_PLAN_REPORT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/LeasingPaymentPlan.vue"),
    },
  ],
};

export default router;
